/* eslint-disable */

/**
 * Toggle accordion on click
 */
import { check_checkout_cookie } from './secondStep/checkoutCookie'

export default function () {
    if (!check_checkout_cookie()) {
        $('.js-checkout-accordion.first').addClass('active');

        $('.js-checkout-accordion.first').find('.js-checkout-accordion-content').slideDown(400, 'swing');
    } else {
        $('.js-checkout-accordion.first').addClass('done');
        $('.js-checkout-accordion.second').addClass('active');

        $('.js-checkout-accordion.second').find('.js-checkout-accordion-content').slideDown(400, 'swing');
    }

    $('.js-checkout-accordion--title').on('click', function () {
        let slide = $(this).closest('.js-checkout-accordion');
        if (!$(slide).hasClass('active') && $(slide).hasClass('done')) {
            // Close opened slide and remove class active
            $('.js-checkout-accordion.active').find('.js-checkout-accordion-content').slideUp(400, 'swing');
            $('.js-checkout-accordion').removeClass('active');

            // Remove class done
            if ($(slide).hasClass('first')) {
                $('.js-checkout-accordion').removeClass('done');
            } else {
                $(slide).removeClass('done');
            }

            // Open clicked slide and add class active
            $(slide).addClass('active');
            $(slide).find('.js-checkout-accordion-content').slideDown(400, 'swing');
        }
    });

    $('.first-step__submit').on('click', function () {
        $('html, body').animate({ scrollTop: 0 }, 400);
        let firstSlide = $('.js-checkout-accordion.first');
        let slide = $('.js-checkout-accordion.second');
        $('.js-checkout-accordion.first').find('.js-checkout-accordion-content').slideUp(400, 'swing');
        $('.js-checkout-accordion').removeClass('active');
        $(firstSlide).addClass('done');
        $(slide).addClass('active');
        $(slide).find('.js-checkout-accordion-content').slideDown(400, 'swing');
    });
}
