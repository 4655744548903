export default function () {
    const order = $('.js-jackpot--container').data('info');
    $.ajax({
        type    : 'POST',
        url     : menaObject.ajaxurl,
        dataType: 'html',
        data    : {
            action: 'jackpot_order_received',
            order : order,
        },
        success : function (response) {
            if (response) {
                response = JSON.parse(response);

                if (response.status == 'processing') {
                    localStorage.removeItem('order');
                    localStorage.removeItem('status');
                }

                window.location.replace(response.url);
            }
        },
    });
}
