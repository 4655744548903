import ProductModal from '../woocommerce/single-product/modal';

/**
 * Click on product card image open modal
 */
export default function () {
    if ($('.page-template-jackpot').length > 0) {
        $('.page-template-jackpot').on('click', '.product-card__image', function (event) {
            event.preventDefault();
            // Get card
            const card = $(this).closest('.js-products--card');
            const button = card.find('.jackpot--add-to-cart');
            let productId = $(card).data('post-id');

            if ($(button).hasClass('type-simple')) {
                // Simple
                let modal = new ProductModal(productId, 'jackpot');

                $('.js-product-modal--close').on('click', function () {
                    modal.destroy();
                });
            } else {
                // Variable
                $(button).click();
            }
        });
    }
}
