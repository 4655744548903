import checkPaketomat from './secondStep/checkPaketomat';
import checkBillingAddress from './secondStep/checkBillingAddress';

export default function () {

    $(document).on('click', '.checkout-data-modal__submit', function (e) {
        e.preventDefault();

        $(this).addClass('loading');

        let paketomat_data = checkPaketomat();

        if (paketomat_data) {
            if (!$('.payment_method_ppcp-gateway').hasClass('active')) {
                const validation = checkBillingAddress();
                if (validation) {
                    $('#place_order').click();
                }
            }
        }

        setTimeout(() => {
            $(this).removeClass('loading');
        }, 500);

    });

    $(document).ready(function ($) {
        var $paypalButton = $('.ppc-button-wrapper');

        $paypalButton.appendTo('.fake-button');
    });

}

