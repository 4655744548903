import productCardImage from './productCardImage';
import sidebar from './sidebar';
import timer from './timer';
import jackpotValidation from './jackpotValidation';
import { unset_checkout_cookie } from '../woocommerce/checkout/secondStep/checkoutCookie'

export default function () {
    if ($('.page-template-jackpot').length > 0) {
        unset_checkout_cookie()
        timer();
        sidebar();
        productCardImage();
        togglePreloader();

        $('.page-template-jackpot').on('click', '.js-jackpot--submit', function (event) {
            event.preventDefault();

            if (($(this).hasClass('special')) || ($(this).hasClass('jackpot-not-used'))) {
                jackpotValidation();
            }

            if ($(this).hasClass('jackpot') && (!$(this).hasClass('jackpot-not-used'))) {
                showSpecialProducts();

                $(this).removeClass('jackpot');
                $(this).addClass('special');
            }
        });

        $('.jackpot__modal-middle__cta').on('click', function (event) {
            event.preventDefault();
            $('.js-jackpot--modal-middle').removeClass('opened');
            $('body').addClass('jackpot-active');

            setTimeout(function () {
                $('.jackpot__modal-next-discord--special').addClass('opened');
                setTimeout(destroyJackpotModal, 5000);
            }, 100);
        });

        if ($('.js-jackpot--main-wrapper').length === 0) {
            $('.js-jackpot--products-wrapper').slideToggle(300);
            $('.js-jackpot__products-sidebar-wrapper').addClass('show');
            $('.js-jackpot--top').slideDown(300);
            breadcrumb();
        }

        if (!getJackpotCookie()) {
            setJackpotCookie('jackpot_visit', window.location.href, 15);
        }
    }
}

// Breadcrumb line calculation
export function breadcrumb() {
    const lines = $('.js-jackpot--breadcrumb-lines');
    const leftMargin = parseInt($('.js-jackpot--breadcrumb div:first-child').css('margin-left'));
    const rightMargin = parseInt($('.js-jackpot--breadcrumb div.last').css('margin-right'));
    let totalWidth = leftMargin + rightMargin;
    $(lines).css('opacity', '1');
    $(lines).css('width', `calc(100% - ${totalWidth}px)`);
    $(lines).css('left', `${leftMargin}px`);
}

function showSpecialProducts() {
    $.ajax({
        type: 'POST',
        url: menaObject.ajaxurl,
        data: {
            action: 'jackpot_special_products_ajax',
            order_id: $('.js-jackpot--container').data('info'),
        },
        beforeSend: function () {
            $('.js-jackpot__products-sidebar-wrapper').addClass('loading');
        },
        success: function (response) {
            if (response) {
                response = JSON.parse(response);
                $('.js-jackpot--products-container').html(response);
                $('.js-jackpot--products').data('type', 'special');
                // $('.js-jackpot--modal-next-discord.jackpot__modal-next-discord--special').addClass('opened');
                // setTimeout(destroyJackpotModal, 5000);

                if ($('.js-jackpot--products-container').hasClass('jackpot')) {
                    $('.js-jackpot--breadcrumb:nth-child(3), .js-jackpot--products-container, .js-jackpot--products, .jackpot__top-timer-svg, .js-jackpot--submit').removeClass('jackpot');
                    $('.js-jackpot--breadcrumb:nth-child(3), .js-jackpot--products-container, .js-jackpot--products, .jackpot__top-timer-svg, .js-jackpot--submit').addClass('special');
                    $('.js-jackpot--products-wrapper.jackpot__products-wrapper--jackpot').css('background-image', 'url(\'' + menaObject.assets + '/images/jackpot/modal-special-bg.jpg\')');
                }
            }
        },
    });
}

function destroyJackpotModal() {
    $('.js-jackpot--modal-next-discord').removeClass('opened');
    $('body').removeClass('jackpot-active');
}

function togglePreloader() {
    setTimeout(function () {
        $('.jackpot-preloader').removeClass('active');
    }, 3000);
}

function setJackpotCookie(name, value, minToExpire) {
    const expirationDate = new Date();
    expirationDate.setTime(expirationDate.getTime() + minToExpire * 60 * 1000);

    const cookieValue = encodeURIComponent(value) + '; expires=' + expirationDate.toUTCString();

    document.cookie = name + '=' + cookieValue + '; path=/';
}

export function getJackpotCookie() {
    const cookieString = document.cookie;
    const cookies = cookieString.split('; ');

    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].split('=');
        if (cookie[0] === 'jackpot_visit') {
            return decodeURIComponent(cookie[1]);
        }
    }

    return null;
}

export function deleteJackpotCookie() {
    const deletionDate = new Date();
    deletionDate.setTime(deletionDate.getTime() - 1); // Set expiration time to the past

    const cookieValue = encodeURIComponent('') + '; expires=' + deletionDate.toUTCString();

    document.cookie = 'jackpot_visit' + '=' + cookieValue + '; path=/';
}