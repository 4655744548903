/**
 * Toggle loader to body
 */
export default function (element) {
    if (element.hasClass('adding')) {
        showLoader(element);
    } else {
        closeLoader();
    }

}

/**
 * Show loader
 */
function showLoader(element) {
    const loader = `<div class="main-loader upsell-loader js-loader">
                                 <div class="sk-circle">
                                      <div class="sk-circle1 sk-child"></div>
                                      <div class="sk-circle2 sk-child"></div>
                                      <div class="sk-circle3 sk-child"></div>
                                      <div class="sk-circle4 sk-child"></div>
                                      <div class="sk-circle5 sk-child"></div>
                                      <div class="sk-circle6 sk-child"></div>
                                      <div class="sk-circle7 sk-child"></div>
                                      <div class="sk-circle8 sk-child"></div>
                                      <div class="sk-circle9 sk-child"></div>
                                      <div class="sk-circle10 sk-child"></div>
                                      <div class="sk-circle11 sk-child"></div>
                                      <div class="sk-circle12 sk-child"></div>
                                </div>
                    </div>`;

    element.append(loader);
    $('.js-loader.upsell-loader').addClass('loading');
}

/**
 * Remove Loader from body
 */
function closeLoader() {
    $('.js-loader.upsell-loader').remove();
}
