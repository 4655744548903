/**
 * Show checkout fields label if stored
 */
export default function () {
    if (menaObject && 'labels' in menaObject && 'checkout' in menaObject.labels) {
        let info_icon = `<svg class="first-step__info-svg" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM8.8921 9.77631H9.7804V11.5541H6.22485V9.77631H7.11374V7.99853H6.22485V6.22075H8.8921V9.77631ZM8.00029 5.33186C7.50921 5.33186 7.11111 4.93389 7.11111 4.44297C7.11111 3.95205 7.50921 3.55409 8.00029 3.55409C8.49137 3.55409 8.88947 3.95205 8.88947 4.44297C8.88947 4.93389 8.49137 5.33186 8.00029 5.33186Z" fill="#777E91"></path></svg>`

        if ('email_info' in menaObject.labels.checkout) {
            let label = menaObject.labels.checkout.email_info

            if (label) {
                let classList = `first-step__info js-checkout-accordion--email-text color-grey-300 mt-1`
                let mask = `<p class="${classList}">${info_icon} ${label}</p>`

                $('#billing_email_field').append(mask)
            }
        }

        if ('phone_info' in menaObject.labels.checkout) {
            let label = menaObject.labels.checkout.phone_info

            if (label) {
                let classList = `first-step__info js-checkout-accordion--phone-text color-grey-300 mt-1`
                let mask = `<p class="${classList}">${info_icon} ${label}</p>`

                $('#billing_phone_field').append(mask)
            }
        }

        if ('address_info' in menaObject.labels.checkout) {
            let label = menaObject.labels.checkout.address_info

            if (label) {
                let classList = `first-step__info js-checkout-accordion--address-text color-grey-300 mt-1`
                let mask = `<p class="${classList}">${info_icon} ${label}</p>`

                $('#billing_address_1_field').append(mask)
            }
        }
    }
}