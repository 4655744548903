export default function () {
    const lines = $('.js-checkout-intro--breadcrumb-lines');
    const leftMargin = parseInt($('.js-checkout-intro--breadcrumb div:first-child').css('margin-left'));
    const rightMargin = parseInt($('.js-checkout-intro--breadcrumb div.last').css('margin-right'));
    let totalWidth = leftMargin + rightMargin;
    $(lines).css('opacity', '1');
    $(lines).css('width', `calc(100% - ${totalWidth}px)`);
    $(lines).css('left', `${leftMargin}px`);

    $('.first-step__submit').on('click', function () {
        $('.js-checkout-intro--breadcrumb.second, .js-checkout-intro--breadcrumb-lines').addClass('active');
    });

    $('.js-checkout-accordion--title').on('click', function () {
        let firstSlide = $(this).closest('.js-checkout-accordion');
        if (!$(firstSlide).hasClass('active') && $(firstSlide).hasClass('done')) {
            $('.js-checkout-intro--breadcrumb.second, .js-checkout-intro--breadcrumb-lines').removeClass('active');
        }
    });

}
