export default function () {
    getUTMParameters();
    getReferrer();
}

function getUTMParameters() {
    var params = new URLSearchParams(window.location.search);
    var utmParams = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'];
    var utmData = {};

    utmParams.forEach(function(param) {
        if (params.has(param)) {
            utmData[param] = params.get(param);
        }
    });

    if (Object.keys(utmData).length > 0) {
        document.cookie = "utm_params=" + JSON.stringify(utmData) + ";path=/";
    }
}

function getReferrer() {
    var referrerCookieName = "referrer";
    if (!getCookie(referrerCookieName)) {
        var referrerValue = document.referrer;
        if (referrerValue) {
            document.cookie = referrerCookieName + "=" + referrerValue + ";path=/";
        }
    }
}

function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}