import addToOrderJackpot, { productCardAnimation } from '../../jackpot/addToOrder';
import gallery from './gallery/gallery';
import orderBox from './order-box/order-box';
import simpleOrderBox from './order-box/simple-order-box';

export default class ProductModal {
    constructor(productID, modalType = '') {
        this.productInfo = {};
        this.getProductInfo(productID, modalType);
    }

    destroy = () => {
        $('.js-product-modal--container').parent().fadeOut(300);

        setTimeout(() => {
            $('.js-product-modal--container').parent().remove();
            $('body').removeClass('modal-opened');
        }, 350);
    };

    getProductInfo = (productID, modalType) => {
        $.ajax({
            type: 'POST',
            url: menaObject.ajaxurl,
            dataType: 'html',
            data: {
                action: 'woocommerce_get_product_modal_info',
                product_id: productID,
                modalType: modalType,
            },
            beforeSend: () => {
                $('body').append(`
                    <div class="c-product-modal hidden">
                        <div class="c-product-modal__container js-product-modal--container">
                            <div class="c-product-modal__close js-product-modal--close">
                                <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20.6582 10.1667L15.9998 14.825L11.3415 10.1667L10.1665 11.3417L14.8248 16L10.1665 20.6584L11.3415 21.8334L15.9998 17.175L20.6582 21.8334L21.8332 20.6584L17.1748 16L21.8332 11.3417L20.6582 10.1667Z" />
                                    <rect x="1" y="1" width="30" height="30" rx="15" stroke-width="2" />
                                </svg>
                            </div>
    
                            <div class="js-c-product-modal--loader c-product-modal__loader lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                        </div>
                    </div>
                `);

                setTimeout(() => {
                    $('.c-product-modal.hidden').removeClass('hidden');
                    $('body').addClass('modal-opened');
                }, 100);
            },
            success: (response) => {
                if (response) {
                    $('.js-product-modal--container').append(response);
                    $('.js-c-product-modal--loader').addClass('hidden');
                    gallery.initializeSwiper();

                    // Normal modal flow
                    if ($('.c-woo-order-box').length > 0 && modalType != 'special' && modalType != 'jackpot') {
                        orderBox.initializeOrderBox();
                    }

                    // Preparation modal for jackpot type
                    if (modalType == 'jackpot' || modalType == 'special') {
                        simpleOrderBox.initializeOrderBox();

                        $('.js-order-box--add-to-cart').on('click', function (e) {
                            e.preventDefault();

                            // Get quantity
                            let quantity = $('.js-order-box--price-select.selected').data('count');

                            if ($('.c-woo-order-box').hasClass('simple')) {
                                // For simple products
                                addToOrderJackpot(productID, modalType, quantity);
                            } else {
                                // For variation products
                                let variationIds = Array();
                                $('.js-variation--toggle.active').each(function () {
                                    let variationId = $(this).data('variation-id');
                                    variationIds.push(variationId);
                                });

                                addToOrderJackpot(variationIds, modalType, quantity);
                            }

                            productCardAnimation(productID);
                        });
                    }

                    if ($('.js-jackpot--products-container').hasClass('special')) {
                        let modalSpecialUltraImg = menaObject.assets + '/images/jackpot/benefits-ultra.png';
                        $('.single-product__bottom-bar').addClass('remove');
                        $('.single-product__bottom-bar').addClass('special');
                        $('.js-bottom-bar-svg').attr('src', modalSpecialUltraImg);
                    }
                }
            },
        });
    };
}
