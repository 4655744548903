import validation from './validation';

export default function () {
    let data = $('.js-payments--payment.active').data('payment-id');
    let input = $('.wc_payment_method input[value="' + data + '"]');
    input.click();

    if (data != 'cod') {
        $('.js-payment--card-box').slideDown(400, 'swing');
    }

    $('.js-payments--payment').on('click', function () {
        data = $(this).data('payment-id');
        input = $('.wc_payment_method input[value="' + data + '"]');

        if (data != 'cod') {
            $('.js-payment--card-box').slideDown(400, 'swing');
        } else {
            $('.js-payment--card-box').slideUp(400, 'swing');
        }

        if (!$(this).hasClass('active')) {
            $('.js-payments--payment').removeClass('active');
            $(this).addClass('active');
            input.click();
        }
    });

    validation();
}
