export default function () {
    $(document).on('click', '.checkout-first-step__form .first-step__submit', function () {
        set_checkout_cookie()
    })


    $(document).on('click', '.checkout-accordion__step.first .js-checkout-accordion--title', function () {
        unset_checkout_cookie()
    })
}

export function check_checkout_cookie() {
    const cookies = document.cookie.split(';');

    for (let i = 0; i < cookies.length; i++) {
        let cookie = cookies[i].trim();
        let cookieName = cookie.split('=')[0];

        if (cookieName === 'MSUPCheckoutCookie') {
            return cookie.split('=')[1];
        }
    }

    return null;
}

function set_checkout_cookie() {
    const name = 'MSUPCheckoutCookie'
    const value = true
    const expires = ''
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

export function unset_checkout_cookie() {
    document.cookie = 'MSUPCheckoutCookie=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/';
}
