export default function () {
    checkCookie();
}

function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = 'expires=' + d.toUTCString();
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
}

function getCookie(cname) {
    let name = cname + '=';
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
}

function checkCookie() {
    let handle = getCookie('handleCookie');
    if (handle == '' || handle == null) {
        $('.js-cookie-bar').addClass('active');
        handle = true;

        if (handle != '' && handle != null) {
            $('.js-cookie-bar--accept').on('click', function (ev) {
                ev.preventDefault();
                setCookie('handleCookie', handle, 30);
                $('.js-cookie-bar').removeClass('active');
            });
        }
    }
}
