import variables from '../../../global/variables';
import calculateFreeShipping from './calculate';
import cart from './cart';
import countdown from './countdown';
import crossSell from './cross-sell';
import shippingBar from './shipping-bar';
import validation from './validation';
import variations from './variations';

export default {
    initializeOrderBox: function () {
        variables.orderBoxVariationSelect = variations.getVariationsSelect();
        variables.orderBoxValidationObject = {};
        variables.addToCartObject = {
            product_id    : $('.c-woo-order-box').data('product-id'),
            quantityPrice : 0,
            cartPrice     : 0,
            crossSellPrice: 0,
            totalPrice    : 0,
            variations    : [],
        };

        variations.initializeVariationSelect();
        variations.initializeListeners();
        variations.addAdditionaVariationsSelect(1);

        crossSell.initializeListeners();

        countdown.startCountDown();

        variables.hash = localStorage.getItem('order_hash');

        if (variables.hash) {
            shippingBar.getOrderPrice();
        } else {
            shippingBar.getCartPrice();
        }

        $('.single-product').on('click', '.js-order-box--price-select', function () {
            $(this).siblings('.selected').removeClass('selected');
            $(this).addClass('selected');

            variables.addToCartObject.quantityPrice = Number($(this).data('price')) * Number($(this).data('count'));

            calculateFreeShipping(Number($('.js-order-box--max-price').data('minimum-price')), variables.addToCartObject.quantityPrice);

            variations.removeAdditionalVariationSelect($(this).data('count'));
            variations.initializeListeners();
        });

        $('.single-product').on('click', '.js-order-box--add-to-cart', function (event) {
            event.preventDefault();
            validation.validateOrderBoxInput();

            if (variables.orderBoxValidationObject.valid) {
                cart.prepareCartObject();
            }
        });

        $('.js-order-box--currently-watching').text(String(Math.floor(Math.random() * 30) + 1));
    },
};
