export function unsetPaketomatData() {
    $('#billing_expedico_points').val('');
    $('#billing_expedico_title').val('');
    $('#billing_expedico_value').val('');
}

export function copyPaketomatFieldValues() {
    if($('#billing_expedico_points').val() !== '') {
        $('.js-paketomat-name').text($('#billing_expedico_title').val());
        $('.js-paketomat-address').text($('#billing_expedico_value').val());
    }
}

function initPaketomat() {
    var paketomatIdCookie = getPaketomatCookie('paketomatId');
    var paketomatAddressCookie = getPaketomatCookie('paketomatAddress');
    var paketomatNameCookie = getPaketomatCookie('paketomatName');

    if (paketomatIdCookie !== null && paketomatAddressCookie !== null && paketomatNameCookie !== null) {
        let data = [];
        data['id'] = paketomatIdCookie;
        data['address'] = paketomatAddressCookie;
        data['name'] = paketomatNameCookie;
        setPaketomatData(data);
    }

    if (typeof options === 'undefined') {
        const pektomatiData = JSON.parse($('#paketomati-json').html());
        let carrierIds = [];
        let bl = [];
        let tr = [];

        let carrierIdsData = pektomatiData.carrier_ids.split(',');
        for (let i = 0; i < carrierIdsData.length; i++) {
            let id = parseInt(carrierIdsData[i]);
            carrierIds.push(id);
        }

        let blData = pektomatiData.default_bounds.bl.split(',');
        for (let i = 0; i < blData.length; i++) {
            let num = parseFloat(blData[i]);
            bl.push(num);
        }

        let trData = pektomatiData.default_bounds.tr.split(',');
        for (let i = 0; i < trData.length; i++) {
            let num = parseFloat(trData[i]);
            tr.push(num);
        }

        const options = {
            carrier_ids: carrierIds,
            default_bounds: {
                bl: [bl[0], bl[1]],
                tr: [tr[0], tr[1]]
            },
            iframe_id: 'widget-expedico-iframe',
        };
        Expedico.choosePickupPoint(expedicoCallback, options);
    }
}

function expedicoCallback(pickupPoint) {
    if (!pickupPoint) {
        return;
    }
    let data = [];
    data['id'] = pickupPoint.id;
    data['address'] = pickupPoint.addressLocal;
    data['name'] = pickupPoint.nameLocal;
    setPaketomatData(data);

    if ($('#billing_house_number_field')
        .hasClass('required')) {
        $('#billing_house_number_field')
            .removeClass('required');
    }

    $('#widget-expedico').removeClass('active');
}

function setPaketomatData(data) {
    let pickupAddress,
        postalCode,
        city;
    const bodyLanguage = $('body')
        .data('language');
    const fullAddress = data['address'];
    const shippingMethods = document.querySelector('.second-step__shipping-methods');
    shippingMethods.scrollIntoView({behavior: 'smooth'});
    let address = fullAddress.split(',');
    for (let i = 0; i < address.length; i++) {
        address[i] = $.trim(address[i]);
    }

    pickupAddress = address[0];
    postalCode = address[1];
    city = address[2];

    // Poland
    if (bodyLanguage === 'pl') {
        postalCode = address[1].substring(0, 6);
        city = address[1].substring(7);
    }

    // Czech
    if (bodyLanguage === 'cs') {
        pickupAddress = address[0] + ' ' + address[1];
        postalCode = address[2];
        city = address[3];
    }

    // Greece
    if (bodyLanguage === 'el') {
        postalCode = address[1].substring(0, 5);
        city = address[1].substring(6);
    }

    $('.js-paketomat-name')
        .text(data['name']);
    $('#billing_expedico_title')
        .val(data['name']);
    $('#billing_expedico_points')
        .val(data['id']);
    $('.js-paketomat-address')
        .text(fullAddress);
    $('#billing_expedico_value')
        .val(fullAddress);

    var addressWords = pickupAddress.split(' ');
    var shortenedAddress = '';
    for (var i = 0; i < addressWords.length; i++) {
        if ((shortenedAddress + addressWords[i]).length <= 35) {
            shortenedAddress += addressWords[i] + ' ';
        } else {
            break;
        }
    }

    $('.extra-pay__desc-data-wrapper')
        .slideDown(300);

    setPaketomatCookie('paketomatId', data['id'], 7);
    setPaketomatCookie('paketomatAddress', data['address'], 7);
    setPaketomatCookie('paketomatName', data['name'], 7);

}

function setPaketomatCookie(name, value, daysToLive) {
    var cookieValue = encodeURIComponent(value);

    var expires = "";
    if (daysToLive) {
        var date = new Date();
        date.setTime(date.getTime() + (daysToLive * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }

    document.cookie = name + "=" + cookieValue + expires + "; path=/";
}

function getPaketomatCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return decodeURIComponent(c.substring(nameEQ.length, c.length));
    }
    return null;
}

export default function () {
    $(document).on('click', '.js-shipping--method.paketomat', function(e) {
        const widgetExpedico = $('#widget-expedico');

        if (widgetExpedico.hasClass('active')) {
            unsetPaketomatData();
            widgetExpedico.removeClass('active');
        } else {
            if ($('script[src="https://widget.expedico.eu/library.js"]').length === 0) {
                $.getScript('https://widget.expedico.eu/library.js', function() {
                    initPaketomat();
                });
            }

            let input = $(this).find('input');

            input.prop('checked', true);

            $('body').trigger('update_checkout');
            widgetExpedico.addClass('active').show();
            document.getElementById('widget-expedico').scrollIntoView({ behavior: 'smooth' });
        }
    });


    $(document).on('click', '.js-shipping--method', function(){
        if(!$(this).hasClass('paketomat')) {
            unsetPaketomatData();
        }
    });
}
