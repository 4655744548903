export default {
    getCurrentDevice: function () {
        if ($(window).width() < 1024) {
            return 'mobile';
        } else if ($(window).width() > 1024) {
            return 'desktop';
        }
    },

    triggerShare: function (device) {
        if (device == 'desktop') {
            if (navigator.clipboard) {
                navigator.clipboard.writeText(window.location.href).then(() => console.log('Text copied to clipboard')).catch(err => console.error('Failed to copy text: ', err));
            } else {
                console.error('Clipboard API not supported');
            }

            $('.c-notice-popup').text('URL je kopiran u međuspremnik!');
            $('.c-notice-popup').removeClass('hidden');

            setTimeout(function () {
                $('.c-notice-popup').addClass('hidden');
            }, 5000);
        } else if (device == 'mobile') {
            navigator.share({
                url: document.location.href,
                title: document.title,
            });
        }
    },
};
