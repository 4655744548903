import variables from '../../../global/variables';
import edgeTagAddToCart from '../../../edgetag/addToCart';
import { dynamicOptionData } from './dynamicVariations';

export default {
    prepareCartObject: function () {
        variables.addToCartObject.simpleProductCount = 0;
        variables.addToCartObject.variations = [];
        variables.addToCartObject.crossSells = [];

        if ($('.c-woo-order-box').hasClass('simple')) {
            variables.addToCartObject.simpleProductCount = Number($('.js-order-box--price-select.selected').data('count'));
        }

        if ($('.c-woo-order-box').hasClass('variable')) {
            let variationSelectComponents = $('.js-variations--select').toArray();

            variationSelectComponents.forEach(function (variationSelect) {
                let selectedVariation = $(variationSelect).find('.js-variation--toggle.active');

                variables.addToCartObject.variations.push({
                    id: $(selectedVariation).data('variation-id'),
                });
            });
        }

        let productCrossSells = $('.js-order-box--cross-sell').toArray();

        if (productCrossSells.length > 0) {
            productCrossSells.forEach(function (crossSellProduct) {
                if ($(crossSellProduct).hasClass('active')) {
                    variables.addToCartObject.crossSells.push({
                        variation_id: $(crossSellProduct).data('variation-id'),
                        product_id  : $(crossSellProduct).data('product-id'),
                    });
                }
            });
        }
    },

    addProducts: function () {
        let dynamicVariations = false;

        if ($('.js-dynamic-variations--variations').length > 0) {
            dynamicVariations = dynamicOptionData;
        }

        $.ajax({
            type    : 'POST',
            url     : menaObject.ajaxurl,
            dataType: 'html',
            data    : {
                action              : 'woocommerce_order_box_add_to_cart',
                product_id          : variables.addToCartObject.product_id,
                simple_product_count: variables.addToCartObject.simpleProductCount,
                variations          : variables.addToCartObject.variations,
                cross_sells         : variables.addToCartObject.crossSells,
                dynamicVariations   : dynamicVariations,
            },

            beforeSend: function () {
                $('.c-woo-order-box').addClass('loading');
            },

            success: function (response) {
                if (response) {
                    response = JSON.parse(response);

                    let productQuantity = $('.js-order-box--price-select.selected').data('count');

                    let product = {
                        id         : variables.addToCartObject.product_id,
                        variantIds : variables.addToCartObject.variations,
                        quantity   : productQuantity,
                        item_price : variables.addToCartObject.quantityPrice / productQuantity,
                        title      : response.title,
                        category   : response.product_categories,
                        image      : response.image,
                        url        : response.product_url,
                        cross_sells: response.cross_sells,
                        currency   : response.currency,
                    };

                    if (!$('html').hasClass('single-product-modal')) {
                        edgeTagAddToCart(product);
                        setTimeout(function () {
                            $('.c-woo-order-box').removeClass('loading');
                            window.location.href = response.url;
                        }, 1500);
                    } else {
                        $('.c-woo-order-box').addClass('loading');
                    }
                }
            },
        });
    },
};
