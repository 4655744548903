export default function () {
    let noticeTrial = 15000;
    $('.mena-wc-notices').addClass('active');

    if ($('.page-template-jackpot').length > 0) {
        noticeTrial = 2300;
    }

    setTimeout(() => {
        $('.mena-wc-notices').removeClass('active');
    }, noticeTrial);

    $('.mena-wc-notices__close').on('click', function () {
        $('.mena-wc-notices').removeClass('active');
    });
}
