export default {
    startCountDown() {
        let countdownDate;
        let currentDate = new Date();

        countdownDate = new Date(currentDate.setHours(currentDate.getHours() + Math.floor(Math.random() * (4 - 1 + 1) + 1)));
        countdownDate = new Date(currentDate.setMinutes(currentDate.getMinutes() + Math.floor(Math.random() * (60 - 1 + 1) + 1)));
        countdownDate = new Date(currentDate.setSeconds(currentDate.getSeconds() + Math.floor(Math.random() * (60 - 1 + 1) + 1)));

        let countdownInterval = setInterval(function () {
            currentDate = new Date().getTime();

            let dateDifference = countdownDate - currentDate;

            let hours = Math.floor((dateDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            let minutes = Math.floor((dateDifference % (1000 * 60 * 60)) / (1000 * 60));
            let seconds = Math.floor((dateDifference % (1000 * 60)) / 1000);

            let output = `${hours}H ${minutes}M ${seconds}S`;

            $('.js-order-box--countdown').text(output);

            if (dateDifference < 0) {
                clearInterval(countdownInterval);
                if ($('#demo').length > 0) {
                    document.getElementById('demo').innerHTML = 'Ponuda je istekla!';
                }
            }
        }, 1000);
    },
};
