export default function () {
    if ($('.js-product-modal--container').length > 0) {
        $('.js-product-modal--container').parent().fadeOut(300);

        setTimeout(() => {
            $('.js-product-modal--container').parent().remove();
            $('body').removeClass('modal-opened');
        }, 350);
    }
}
