import cardPlaceOrder from './cardPlaceOrder';
import checkBillingAddress from './checkBillingAddress';
import modal from './modal';
import finishOrder from '../finishOrder';

/**
 * Second Step Validation
 */
export default function () {
    // Get Order info
    orderInfo();

    // Remove local storage and proceed with default woo checkout order
    cardPlaceOrder();

    $('.second-step__submit').on('click', function (ev) {
        ev.preventDefault();
        if ($('input#terms_and_conditions').length > 0) {
            if (!checkTerms()) {
                return;
            }
        }
        let address_data = checkBillingAddress();

        // Finish order and redirect
        if (address_data) {
            if ($('.checkout-data-modal').length > 0) {
                modal(address_data);
            } else {
                finishOrder(address_data);
            }
        }
    });
}

// Get Order info
function orderInfo() {
    let allProducts = Array.from(document.querySelectorAll('.woocommerce-cart-form__cart-item'));
    // Store product ids in array
    let productIds = allProducts.map(function (product) {
        return product.dataset.id;
    });
}

// Show success
function nextStep() {
    $('.js-checkout-accordion').removeClass('active');
    $('.js-checkout-accordion .js-checkout-accordion-content').slideUp(400, 'swing');
    $('.js-checkout-accordion:nth-child(2)').addClass('done');
    $('.js-checkout-accordion:nth-child(3)').addClass('active');
    $('.js-checkout-accordion:nth-child(3) .js-checkout-accordion-content').slideDown(400, 'swing');
    document.querySelector('.js-checkout-accordion').scrollIntoView({ behavior: 'smooth' });
}

export function checkTerms() {
    if (!$('input#terms_and_conditions').is(':checked')) {
        $('.terms-container .pulsating-circle').addClass('error');
        $('.terms-container .mena-woocommerce-form__item-error').removeClass('hide');
        return false;
    }

    $('.terms-container .pulsating-circle').removeClass('error');
    $('.terms-container .mena-woocommerce-form__item-error').addClass('hide');
    return true;
}
