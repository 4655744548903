export default function () {
    $(document).on('click', '.first-step__form-conditional-button', function() {
        let $this = $(this);
    
        $this.siblings().removeClass('active');
        $this.addClass('active');
    
        let action = $this.hasClass('company') ? 'slideDown' : 'slideUp';
        $('.first-step__form-company')[action](400, 'swing');
    });
    


    $(document).on('change', '.first-step__form-company-checkbox input', function () {
        if ($(this).prop('checked')) {
            $('.first-step__form-company__tax').slideDown(400, 'swing');
        } else {
            $('.first-step__form-company__tax').slideUp(400, 'swing');
        }
    })
}
