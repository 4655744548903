export default function CallCenter() {
    let searchTimeout;
    let selectedProductId;

    $('#call-center-add-product').off('click').on('click', function() {
        showModal();
    });

    $('#search-query').off('input').on('input', function() {
        var query = $(this).val();
        if (query.length >= 3) {
            clearTimeout(searchTimeout);
            searchTimeout = setTimeout(function() {
                $.ajax({
                    type: 'POST',
                    url: wc_checkout_params.ajax_url,
                    data: {
                        action: 'search_products',
                        query: query
                    },
                    success: function(data) {
                        $('#search-results').html(data);
                    }
                });
            }, 300);
        }
    });

    $('#search-results').off('click', '.product-result').on('click', '.product-result', function() {
        const price = $(this).data('price');
        const productId = $(this).data('product-id');
        selectedProductId = productId;

        $('#selected-product-price').text('Default product price: $' + price);
        $('#product-price').val(price);

        $('.product-result.selected').removeClass('selected');
        $(this).addClass('selected');
    });

    $('#confirm-button').off('click').on('click', function() {
        var customPrice = $('#product-price').val();
        if (selectedProductId && customPrice) {
            $.ajax({
                type: 'POST',
                url: wc_checkout_params.ajax_url,
                data: {
                    action: 'add_product_to_cart',
                    product_id: selectedProductId,
                    price: customPrice
                },
                success: function(response) {
                    console.log(response);
                    if (response.success) {
                        alert('Product added to cart with price: $' + customPrice);
                        closeModal();
                        $('#update-cart').click();
                    } else {
                        alert('Error adding product to cart');
                    }
                }
            });
        } else {
            alert('Please select a product and enter a valid price.');
        }
    });

    function showModal() {
        $('.callcenter-data-modal').css('display', 'flex');
    }

    function closeModal() {
        $('.callcenter-data-modal').css('display', 'none');
        setTimeout(function () {
            $('#search-results').html('');
            $('#selected-product-price').text('');
            $('#product-price').val('');
            selectedProductId = null;
        }, 400);
    }

    $('.js-product-modal--close').off('click').on('click', closeModal);

    function freeShipping() {
        $.ajax({
            type: 'POST',
            url: wc_checkout_params.ajax_url,
            data: {
                action: 'apply_free_shipping',
            },
            success: function(response) {
                if (response.success) {
                    alert(response.data.message);
                    $(document.body).trigger("update_checkout");
                    $('#update-cart').click();
                } else {
                    alert('Failed to apply free shipping.');
                }
            },
            error: function() {
                alert('Failed to apply free shipping.');
            }
        });
    }

    $('#call-center-free-shipping').off('click').on('click', function(e) {
        e.preventDefault();
        freeShipping();
    });

    function freeCod() {
        $.ajax({
            type: 'POST',
            url: wc_checkout_params.ajax_url,
            data: {
                action: 'apply_free_cod',
            },
            success: function(response) {
                if (response.success) {
                    alert(response.data.message);
                    $(document.body).trigger("update_checkout");
                    $('#update-cart').click();
                } else {
                    alert('Failed to apply Free COD.');
                }
            },
            error: function() {
                alert('Failed to apply Free COD.');
            }
        });
    }

    $('#call-center-free-cod').off('click').on('click', function(e) {
        e.preventDefault();
        freeCod();
    });

    $('#call-center-add-coupon').off('click').on('click', function(e) {
        e.preventDefault();
        $('.coupon-modal').css('display', 'flex');
    });

    $('.close-coupon-modal').off('click').on('click', function() {
        $('.coupon-modal').css('display', 'none');
    });

    $('#apply-coupon').off('click').on('click', function() {
        let discountPercentage = $('#discount-percentage').val();
        if (discountPercentage) {
            $.ajax({
                type: 'POST',
                url: wc_checkout_params.ajax_url,
                data: {
                    action: 'apply_coupon',
                    discount: discountPercentage
                },
                success: function(response) {
                    if (response.success) {
                        alert('Coupon applied successfully!');
                        $('.coupon-modal').css('display', 'none');
                        $('#update-cart').click();
                    } else {
                        alert('Failed to apply coupon.');
                    }
                },
                error: function() {
                    alert('An error occurred while applying the coupon.');
                }
            });
        } else {
            alert('Please enter a valid discount percentage.');
        }
    });
}

$(document).ready(function() {
    CallCenter();
    $('#update-cart').click();
    $(document.body).trigger("update_checkout");
});
