/**
 * SEND ADD TO CART EVENT TO EDGETAG
 */
export default function (product = {}) {

    let contents = [];
    let value = 0;

    if (product.variantIds && product.variantIds.length > 0) {
        let tempObj = {};

        product.variantIds.forEach(id => {
            if (!tempObj[id.id]) {
                tempObj[id.id] = {
                    id: id.id,
                    quantity: 1,
                    item_price: product.item_price,
                    title: product.title,
                    category: product.category,
                    image: product.image,
                    url: product.url,
                };
            } else {
                tempObj[id.id].quantity++;
            }
        });

        contents = Object.values(tempObj);
    } else {
        contents.push({
            id: product.id,
            quantity: product.quantity,
            item_price: product.item_price,
            title: product.title,
            category: product.category,
            image: product.image,
            url: product.url,
        });
    }

    value = product.item_price * product.quantity;

    if (product.cross_sells && product.cross_sells.length > 0) {

        product.cross_sells.forEach(crossSell => {
            contents.push({
                id: crossSell.id,
                quantity: 1,
                item_price: crossSell.price,
                title: crossSell.title,
                category: crossSell.categories,
                image: crossSell.image_url,
                url: crossSell.permalink,
            });

            value += crossSell.price;
        });
    }

    if (menaObject.edgetag) {
        edgetag('tag', 'AddToCart', {
            currency: product.currency,
            value: value,
            contents: contents,
        });
    }
}
