import scrollTrigger from './scrollTrigger';

export default function () {
    scrollTrigger();

    let stickyHeader = function () {
        let body = $('body');

        /**
         * Hide top and bottom bar if page template is Single Product or Jackpot
         */
        if (body.hasClass('page-template-jackpot') || body.hasClass('page-template-special')) {
            $('.header-bar').slideDown(0);
            $('.header-top').slideUp(0);
        }

        /**
         * Grab notice from content and place it in header on Jackpot if screen is lower than tablet.
         */
        if (body.hasClass('page-template-jackpot')) {
            if ($('.jackpot__top-notice').length > 0 && $(window).width() < 768) {
                let topNotice = $('.jackpot__top-notice').detach();

                $('header').append(topNotice);
                $('.jackpot__top-notice').slideDown(300);
            } else {
                $('.jackpot__top-notice').slideDown(300);
            }
        }
    };

    window.addEventListener('load', stickyHeader);
    window.addEventListener('resize', stickyHeader);
}
