import variables from '../../../global/variables';
import toggleCheckoutSidebarLoader from '../../../components/toggleCheckoutSidebarLoader';
import toggleServicesLoader from '../../../components/toggleServicesLoader';
import { updateCheckout } from '../updateCart';
import edgeTagAddToCart from '../../../edgetag/addToCart';


function createServiceSwiper() {
    let swiper = new Swiper('.js-third-step__service-product', {
        autoplay: {
            delay: 0,
        },
        loop: true,
        speed: 3500,
        spaceBetween: 16,
        grabCursor: true,
    });
}




function toggleService(value, productId) {

    var clickedElement = $('.js-additional-service--checkbox[data-product-id="' + productId + '"]').closest('.js-additional-services-item');

    if(value) {

        clickedElement.addClass('adding');
        toggleServicesLoader(clickedElement);

        $.ajax({
            type: 'POST',
            url: menaObject.ajaxurl,
            data: {
                'action': 'woocommerce_ajax_add_additional_service_to_cart',
                'product_id': productId,
            },
            success: function(response) {
                clickedElement.removeClass('adding');
                toggleServicesLoader(clickedElement);

                $('.js-additional-service--checkbox[data-product-id="' + productId + '"]').addClass('active');
                if (response.success) {

                    const product = {
                        id: response.data.id,
                        quantity: response.data.quantity,
                        item_price: response.data.item_price,
                        title: response.data.title,
                        category: response.data.category,
                        image: response.data.image,
                        url: response.data.url,
                        currency: response.data.currency,
                    };
                    edgeTagAddToCart(product);

                    $('#update-cart').click();
                }
                updateCheckout();
                toggleCheckoutSidebarLoader();
            },
            error: function() {
                clickedElement.removeClass('adding');
                toggleServicesLoader(clickedElement);
                alert('There was an error adding the product to the cart.');
            },
        });

    } else {

        clickedElement.addClass('adding');
        toggleServicesLoader(clickedElement);

        $.ajax({
            type: 'POST',
            url: menaObject.ajaxurl,
            data: {
                'action': 'remove_additional_service_product_from_cart',
                'product_id': productId,
            },
            success: function(response) {
                $('.js-mena-cart-cross-sell[data-post-id="' + productId + '"]').removeClass('active');
                clickedElement.removeClass('adding');
                toggleServicesLoader(clickedElement);
                if (response.success) {
                    $('#update-cart').click();
                }
                updateCheckout();
                toggleCheckoutSidebarLoader();
            },
            error: function() {
                alert('There was an error removing the product from the cart.');
            },
        });

    }

}




/**
 * Toggle service, and update fees
 */
export default function () {

    async function handleServiceToggle() {
        toggleCheckoutSidebarLoader();
        var productId = $('.js-surprise-product').data('product-id');
        $('.third-step__service').slideToggle(400, 'swing');

        if ($('.js-third-step__service-cta').hasClass('added')) {
            $('.js-third-step__service-cta').removeClass('added');
            $('.js-additional-services-item[data-product-id="' + productId + '"]').removeClass('active');
            await toggleService(false, productId);
        } else {
            $('.js-third-step__service-cta').addClass('added');
            $('.js-additional-services-item[data-product-id="' + productId + '"]').addClass('active');
            await toggleService(true, productId);
        }
        toggleCheckoutSidebarLoader();
    }

    $(document).on('click', '.js-third-step__service-cta', async function () {
        await handleServiceToggle();
    });

    $(document).on('click', '.js-additional-service--checkbox', async function () {

        var productId = $(this).data('product-id');

        if(!$(this).hasClass('js-surprise-product')) {
            toggleCheckoutSidebarLoader();
            if ($('.js-additional-services-item[data-product-id="' + productId + '"]').hasClass('active')) {
                $('.js-additional-services-item[data-product-id="' + productId + '"]').removeClass('active');
                await toggleService(false, productId);
            } else {
                $('.js-additional-services-item[data-product-id="' + productId + '"]').addClass('active');
                await toggleService(true, productId);
            }
            toggleCheckoutSidebarLoader();

        } else {
            await handleServiceToggle();
        }

    });

    $(document).on('click', '.second-step__sidebar-remove', async function () {

        var productId = $(this).data('product-id');
        if(!$('.js-additional-services-item[data-product-id="' + productId + '"]').hasClass('js-surprise-product-container')) {
            toggleCheckoutSidebarLoader();
            await toggleService(false, productId);
            $('.js-additional-services-item[data-product-id="' + productId + '"]').removeClass('active');
            toggleCheckoutSidebarLoader();
        } else {
            toggleCheckoutSidebarLoader();

            $('.third-step__service').slideToggle(400, 'swing');
            $('.js-third-step__service-cta').removeClass('added');
            $('.js-additional-services-item[data-product-id="' + productId + '"]').removeClass('active');
            await toggleService(false, productId);

            toggleCheckoutSidebarLoader();

        }

    });

    if ($('.js-third-step__service-product').length > 0) {
        if (variables.swiperAssetsLoaded) {
            return createServiceSwiper();
        } else {
            loadjs([menaObject.assets + '/js/swiper.min.js', menaObject.assets + '/css/swiper.css'], function () {
                return createServiceSwiper();
            });
        }
    }
}
