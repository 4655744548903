export default function () {
    $('.js-payment--submit').on('click', function (ev) {
        ev.preventDefault();

        if ($('.mena-woocommerce-form__item').hasClass('error')) {
            $('.mena-woocommerce-form__item').removeClass('error');
            $('.mena-woocommerce-form__item-error').remove();
        }

        const data = new Object();
        let isError = false;
        let allFields = Array.from(document.querySelectorAll('.mena-woocommerce-form__item'));

        allFields.forEach(function (item) {
            let field = $(item);
            let input = field.find('.input-text');
            let value = input.val();
            let id = input.attr('id');
            let key = id.slice(6);

            if (value == '' && field.hasClass('validate-required')) {
                let message = 'Obavezno polje';
                let error = '<span class="mena-woocommerce-form__item-error">' + message + '</span>';
                isError = true;
                field.append(error);
                field.addClass('error');
            } else {
                data[key] = value;
            }
        });

        if (isError == false) {
            const payment = $('.js-payments--payment.active').data('payment-id');
            const order = localStorage.getItem('order');
            let key;

            if (!order) {
                const queryString = window.location.search;

                if (queryString) {
                    const urlParams = new URLSearchParams(queryString);
                    key = urlParams.get('key');
                }
            }

            if (order || key) {
                $.ajax({
                    type: 'POST',
                    url: menaObject.ajaxurl,
                    data: {
                        action: 'payment_page_validation',
                        order: order,
                        key: key,
                        address: data,
                        payment: payment,
                    },
                    beforeSend: function () {
                        $('.mena-pay__container').addClass('loading');
                    },
                    success: function (response) {
                        if (response) {
                            // Remove Local Storage
                            localStorage.removeItem('order');
                            localStorage.removeItem('status');
                            window.location.replace(response);
                        } else {
                            // Remove Local Storage
                            localStorage.removeItem('order');
                            localStorage.removeItem('status');
                            $('.js-payment--submit').submit();
                        }

                        $('.mena-pay__container').removeClass('loading');
                    },
                });
            }
        }
    });
}
