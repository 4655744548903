/**
 * Show paketomat error
 */
function showPaketomatError(field, message, service) {
	if ($('.js-shipping--method.active').hasClass('paketomat') || $('.js-shipping--method.active').hasClass('pickup-point')) {
		let error = `<span class="mena-woocommerce-form__item-error">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_3828_51537)">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM8.00286 9.34492L10.391 11.7331L11.6485 10.4756L9.26035 8.08743L11.6487 5.69913L10.3912 4.44164L8.00286 6.82994L5.61452 4.4416L4.35703 5.69909L6.74537 8.08743L4.35719 10.4756L5.61468 11.7331L8.00286 9.34492Z" fill="#FF5959"/>
                </g>
                <defs>
                    <clipPath id="clip0_3828_51537">
                        <rect width="16" height="16" fill="white"/>
                    </clipPath>
                </defs>
            </svg>
            ${message}
        </span>`;

		$(field).find('.extra-pay__desc').after(error);

		if (service === 'expedico') {
			$('.js-shipping--method.paketomat.active').addClass('error');

		} else {
			$('.pickup-point-select2').addClass('error');
			$('.js-shipping--method.pickup-point').addClass('error');
		}
	}
}

/**
 * Remove paketomat error
 */
function removePaketomatError() {
	if ($('.js-shipping--method.paketomat').hasClass('error')) {
		$('.js-shipping--method.paketomat').removeClass('error');
		$('.js-shipping--method.paketomat .mena-woocommerce-form__item-error').remove();
	}

	if ($('.js-shipping--method.pickup-point').hasClass('error')) {
		$('.js-shipping--method.pickup-point').removeClass('error');
		$('.js-shipping--method.pickup-point .mena-woocommerce-form__item-error').remove();
		$('.pickup-point-select2').removeClass('error');
	}
}

export default function () {
	// Remove paketomat error
	removePaketomatError();

	let isError = false;
	let service = '';
	let message = menaObject.labels.global.required;
	let paketomat = $('.js-shipping--method.paketomat.active');
	let pickup_point = $('.js-shipping--method.pickup-point.active');

	// Check if the paketomat field exists
	if (paketomat.length === 0 && pickup_point.length === 0) {
		return true;
	}

	// Check if any of the specified fields are empty
	if (paketomat.length !== 0 && (!document.querySelector('#billing_expedico_points').value ||
		!document.querySelector('#billing_expedico_title').value ||
		!document.querySelector('#billing_expedico_value').value)) {
		isError = true;
		service = 'expedico';
		showPaketomatError(paketomat, message, service);
	}

	if (pickup_point.length > 0 && pickup_point.find('.pickup_point').val() === '') {
		isError = true;
		service = 'pickup_point';
		showPaketomatError(pickup_point, message, service);
	}

	if (isError === false) {
		return true;
	} else {
		$('html, body').animate({
			scrollTop: $('.woocommerce-billing-fields').offset().top,
		}, 2000);
		return false;
	}
}
