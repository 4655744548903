import productCardImage from './productCardImage';
import products from './products';
import specialValidation from './specialValidation';

export default function () {
    $('.js-special--products').slideDown(400, 'swing');

    if ($('.page-template-special').length > 0) {
        if ($(window).width() < 1024) {
            $('.js-special__products-sidebar-wrapper').on('click', function () {
                if ($(this).hasClass('active')) {
                    $(this).removeClass('active');
                    $('.special__products-sidebar--new .special__products-sidebar-items, .special__products-sidebar--new .special__products-sidebar-savings').slideUp(400, 'swing');
                } else {
                    $(this).addClass('active');
                    $('.special__products-sidebar--new .special__products-sidebar-items, .special__products-sidebar--new .special__products-sidebar-savings').slideDown(400, 'swing');
                }
            });
        }

        $('.js-sidebar--ex-order').on('click', function () {
            if ($(this).hasClass('active')) {
                $(this).removeClass('active');
                $('.special__products-sidebar--ex .special__products-sidebar-items, .special__products-sidebar--ex .special__products-sidebar-savings').slideUp(400, 'swing');
            } else {
                $(this).addClass('active');
                $('.special__products-sidebar--ex .special__products-sidebar-items, .special__products-sidebar--ex .special__products-sidebar-savings').slideDown(400, 'swing');
            }
        });
    }

    // Call functions
    products();
    specialValidation();
    productCardImage();
}
