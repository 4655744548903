export default function () {
    $('.js-back-to-top').on('click', function () {
        $([document.documentElement, document.body]).animate(
            {
                scrollTop: 0,
            },
            0
        );
    });
}
