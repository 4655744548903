import variables from '../../../global/variables';
import calculateFreeShipping from './calculate';
import cart from './cart';
import countdown from './countdown';
import crossSell from './cross-sell';
import shippingBar from './shipping-bar';
import validation from './validation';
import variations from './variations';
import { duplicateDynamicOptionContainer, dynamicVariations } from './dynamicVariations';

export default {
    initializeOrderBox: function () {
        if ($('.js-dynamic-variations--variations').length > 0) {
            dynamicVariations();
        }

        variables.orderBoxVariationSelect = variations.getVariationsSelect();
        variables.orderBoxValidationObject = {};
        variables.addToCartObject = {
            product_id    : $('.c-woo-order-box').data('product-id'),
            quantityPrice : 0,
            quantity      : 0,
            cartPrice     : 0,
            crossSellPrice: 0,
            totalPrice    : 0,
            variations    : [],
        };

        variations.initializeVariationSelect();
        variations.initializeListeners();
        variations.addAdditionaVariationsSelect(1);

        crossSell.initializeListeners();

        countdown.startCountDown();

        variables.hash = localStorage.getItem('order_hash');

        if (variables.hash) {
            shippingBar.getOrderPrice();
        } else {
            shippingBar.getCartPrice();
        }

        $('.single-product').on('click', '.js-order-box--price-select', function () {
            $(this).siblings('.selected').removeClass('selected');
            $(this).addClass('selected');

            let quantityNumber = Number($(this).data('count'));
            variables.addToCartObject.quantity = quantityNumber;
            variables.addToCartObject.quantityPrice = Number($(this).data('price')) * Number($(this).data('count'));

            calculateFreeShipping(Number($('.js-order-box--max-price').data('minimum-price')), variables.addToCartObject.quantityPrice);

            variations.removeAdditionalVariationSelect($(this).data('count'));
            variations.initializeListeners();

            // Change bottom bar price
            let quantity = Number(variables.addToCartObject.quantity);
            if (quantity === 0) {
                quantity = 1;
            }
            let fullPrice = $('.c-woo-header__price').data('full-price') * quantity;
            let bottomBarCurrency = $('.js-bottom-bar-price .woocommerce-Price-currencySymbol').text();
            let percent = (variables.addToCartObject.quantityPrice / fullPrice) * 100 - 100;
            $('.js-bottom-bar-price').text(variables.addToCartObject.quantityPrice.toFixed(2));
            $('.js-bottom-bar-price').append(bottomBarCurrency);
            $('.js-bottom-bar-regular-price').text(fullPrice.toFixed(2));
            $('.js-bottom-bar-regular-price').append(bottomBarCurrency);
            $('.single-product__bottom-bar-percent .percent').text(percent.toFixed(0));

            // Copy dynamic variations
            if ($('.js-dynamic-variations--variations').length > 0) {
                duplicateDynamicOptionContainer(quantityNumber);
            }
        });

        $('.single-product').on('click', '.js-order-box--add-to-cart', function (event) {
            event.preventDefault();
            validation.validateOrderBoxInput();

            if (variables.orderBoxValidationObject.valid) {
                cart.prepareCartObject();
                cart.addProducts();
            }
        });

        $('.js-order-box--currently-watching').text(String(Math.floor(Math.random() * 30) + 1));
    },
};
