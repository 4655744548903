export default function (address_data) {
    showModal();
    closeModal();

    // Get data
    const labelsData = menaObject.labels.checkout;
    const labels = {
        phone                   : labelsData['billing_phone_label'],
        email                   : labelsData['billing_email_label'],
        first_name              : labelsData['billing_first_name_label'],
        last_name               : labelsData['billing_second_name_label'],
        address_1               : labelsData['billing_street_label'],
        house_number            : labelsData['billing_house_number_label'],
        city                    : labelsData['billing_city_label'],
        postcode                : labelsData['billing_postal_code_label'],
        buyer_tax_id            : labelsData['billing_tax_number_label'],
        company_person_name     : labelsData['billing_company_person_name_label'],
        company_person_last_name: labelsData['billing_company_second_person_name_label'],
        company_name            : labelsData['billing_company_name_label'],
        company_address         : labelsData['billing_company_address_label'],
    };
    const excludedKeys = ['expedico_points', 'mena_order'];

    // Append data
    $('.checkout-data-modal__sidebar').html('')
    $('.second-step__sidebar').clone().appendTo('.checkout-data-modal__sidebar');

    $('.checkout-data-modal__content').html('')
    $.each(address_data, function (key, value) {
        if (value && (!excludedKeys.includes(key))) {
            if (key === 'company_person_last-name') {
                key = 'company_person_last_name';
            }

            const dataItem = `<div class="mb-1">
                                <span class="font-bold">${labels[key]}:</span>
                                <span>${value}</span>
                              </div>`;

            $('.checkout-data-modal__content').append(dataItem);
        }
    });

    // Finished order
    /*$('.checkout-data-modal__submit').on('click', function (ev) {
        ev.preventDefault();
        $('.c-product-modal__loader').removeClass('hidden');
        $('.checkout-data-modal__content, .checkout-data-modal__submit').css('opacity', 0.4);
        $('.checkout-data-modal, body').css('pointer-events', 'none');
        finishOrder(address_data);
    });*/
}

/**
 * Show checkout modal
 */
function showModal() {
    $('.checkout-data-modal').removeClass('hidden');
}

/**
 * Close checkout modal
 */
function closeModal() {
    $('.checkout-data-modal').removeClass('hidden');
    $('.js-product-modal--close').on('click', function () {
        $('.checkout-data-modal').addClass('hidden');
        setTimeout(function () {
            $('.checkout-data-modal__content').empty();
            $('.checkout-data-modal__sidebar').empty();
        }, 400);
    });
}
