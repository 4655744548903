export default function () {
    const headerCart = $('.js-header--cart');
    const container = $('.js-header--cart-container');
    const buttonClick = $('.js-header--cart-container .header-cart__cta');

    buttonClick.on('click', function (e) {
        window.location.replace($(this).attr('href'));
    });
    // Toggle container if cart is empty
    headerCart.on('click', function (e) {
        e.preventDefault();
        if (headerCart.find('.header-cart__wrapper').hasClass('empty')) {
            if (!container.hasClass('active')) {
                container.slideDown(400, 'swing');

                setTimeout(() => {
                    container.addClass('active');
                }, 400);
            }
        } else {
            window.location.replace(menaObject.checkout_url);
        }
    });

    // Close empty container on click outside box
    $(document).on('click', function (e) {
        if (container.hasClass('active')) {
            if (!$(e.target).closest('.js-header--cart-container').length) {
                container.slideUp(400, 'swing');
                setTimeout(() => {
                    container.removeClass('active');
                }, 400);
            }
        }
    });
}
