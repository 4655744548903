export default {
    initializeListeners: function () {
        setTimeout(() => {
            $(document).on('click', '.wc-tabs li a', function (event) {
    
                let tabName = $(this).parent().attr('id');
                let tabContentElement = $(`.wc-tab[aria-labelledby=${tabName}]`);
    
                $([document.documentElement, document.body]).animate(
                    {
                        scrollTop: $(tabContentElement).offset().top - 32,
                    },
                    0
                );
            });
        }, 1000);
    },
};
