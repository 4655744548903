import { removeAllLocalStorage } from '../global';

export default function () {
    // Remove all errors
    removeErrors();

    // Get all needed data
    const $body = $('body');
    const language = $body.data('language');
    const data = new Object();
    const minCharLabel = menaObject.labels.global.min_char;
    const maxCharLabel = menaObject.labels.global.max_char;
    const onlyNumbersLabel = menaObject.labels.global.only_numbers;
    const onlyTextLabel = menaObject.labels.global.only_text;
    const addNumbers = menaObject.labels.global.add_numbers;
    const addLetters = menaObject.labels.global.add_letters;
    let message = menaObject.labels.global.required;
    let isError = false;
    let allFields = Array.from(document.querySelectorAll('.woocommerce-billing-fields__field-wrapper .mena-woocommerce-form__item'));

    // Check company fields
    if ($('.js-company-button.company').
        hasClass('active')) {
        allFields = Array.from(document.querySelectorAll('.mena-woocommerce-form__item'));

        let companyCheckbox = false;
        if ($('.third-step__form-company-checkbox input').prop('checked') === true) {
            companyCheckbox = true;
        }
        data['is_tax'] = companyCheckbox;
    }

    // Get address data or throw error
    allFields.forEach(function (item) {
        let field = $(item);
        let input = field.find('input');

        // Select
        if (input.length === 0) {
            input = field.find('select');
        }

        // Get id and value
        let id = input.attr('id');
        let value = input.val();

        if (id === undefined) {
            id = $(item).attr('id');
        }

        let key = id.slice(8);
        // Check if any required is empty
        if ((value === '' || value == '0') && field.hasClass('required')) {
            // Check rest of data
            showError(field, message);
            isError = true;

            if ($('.js-shipping--method').hasClass('paketomat')) {
                if ((id == 'billing_address_1') || (id == 'billing_city') || (id == 'billing_postcode')) {
                    $('#widget-expedico').addClass('error');

                    setTimeout(function () {
                        document.querySelector('#widget-expedico').scrollIntoView();
                    }, 500);

                    return;
                }
            }
        } else {
            if (field.hasClass('required')) {
                // Check phone
                if (key === 'phone') {
                    // remove spaces
                    value = value.replace(/\s/g, '');

                    let minPhoneChar = 9;

                    if (language === 'sk' || language === 'el') {
                        minPhoneChar = 10;
                    }

                    if (value.length < minPhoneChar) {
                        showError(field, minCharLabel.replace(/\{num\}/g, minPhoneChar));
                        isError = true;
                    } else if (!isNumber(value)) {
                        showError(field, onlyNumbersLabel);
                        isError = true;
                    }
                }

                // Check email
                if (key === 'email') {
                    let emailData = checkEmail(value);

                    if (emailData === 'error') {
                        showError(field, menaObject.labels.global.invalid_email_format);
                        isError = true;
                    } else if (value.length < 8) {
                        showError(field, minCharLabel.replace(/\{num\}/g, 8));
                        isError = true;
                    }
                }

                // Check zip code
                if (key === 'postcode') {
                    let minCharForPostalCode = 2;
                    let zipCodeCheck = zipCodesValidator(value);
                    let firstHyphenIndex = value.split('-').length - 1;

                    if ((language === 'bg') || language === 'sl') {
                        minCharForPostalCode = 4;
                    }

                    if ((language === 'it') || (language === 'hr') || language === 'sk' || language === 'cs') {
                        minCharForPostalCode = 5;
                    }

                    if (zipCodeCheck && value !== '') {
                        showError(field, zipCodeCheck);
                        isError = true;
                    } else if (value.length < minCharForPostalCode) {
                        isError = true;
                        showError(field, minCharLabel.replace(/\{num\}/g, minCharForPostalCode));
                    } else if (!isNumber(value, language)) {
                        showError(field, onlyNumbersLabel);
                        isError = true;
                    } else if (firstHyphenIndex > 1) {
                        showError(field, onlyNumbersLabel);
                        isError = true;
                    }
                }

                // Check city
                if (key === 'city') {
                    if (value === null) {
                        value = $('#billing_mena_state_field :selected').text();
                    }

                    if (value.length < 2) {
                        showError(field, minCharLabel.replace(/\{num\}/g, 2));
                        isError = true;
                    }

                    if (containsNumbers(value)) {
                        showError(field, onlyTextLabel);
                        isError = true;
                    }
                }

                // Check address_1
                if (key === 'address_1') {
                    if (value.length < 3) {
                        showError(field, minCharLabel.replace(/\{num\}/g, 3));
                        isError = true;
                    } else if (value.length > 35) {
                        showError(field, maxCharLabel.replace(/\{num\}/g, 35));
                        isError = true;
                    } else if (!value.match(/[a-zA-Zа-яА-Яα-ωΑ-Ω]/)) {
                        showError(field, addLetters);
                        isError = true;
                    }
                }

                // House number
                if (key === 'house_number') {
                    if (value.length < 1) {
                        showError(field, minCharLabel.replace(/\{num\}/g, 1));
                        isError = true;
                    }

                    if (!(/\d/.test(value))) {
                        showError(field, addNumbers.replace(/\{num\}/g, 1));
                        isError = true;
                    }
                }

                // State
                value = value.trim();
                if (key === 'mena_state' && value.length == 0) {
                    showError(field, menaObject.labels.global.required);
                    isError = true;
                }
            }

            if (key === 'mena_order' && value == '') {
                value = localStorage.getItem('order_hash');
            }

            if (value) {
                data[key] = value.trim();
            }
        }
    });

    if (isError === false) {
        return data;
    } else {
        document.querySelector('.mena-woocommerce-form__item-error').scrollIntoView();
        return false;
    }
}

/**
 * Email validation
 */
function checkEmail(value) {
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    if (!emailRegex.test(value)) {
        return 'error';
    }
}

function isNumber(value, language) {
    let numberRegex = /^[0-9]+$/; // Regex to match only numbers
    let isNumber = false;

    if (numberRegex.test(value)) {
        isNumber = true;
    }

    // Polish postcode format xx-xxx
    if (language === 'pl') {
        let polishRegex = /^\d{2}-\d{3}$/;
        if (polishRegex.test(value)) {
            isNumber = true;
        }
    }

    // Czech postcode format xxx xx
    if (language === 'cs' || language === 'sk') {
        let czechRegex = /^\d{3} \d{2}$/;
        if (czechRegex.test(value)) {
            isNumber = true;
        }
    }

    return isNumber;
}

/**
 * Show input error messages
 */
function showError(field, message) {
    let error = `<span class="mena-woocommerce-form__item-error">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_3828_51537)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM8.00286 9.34492L10.391 11.7331L11.6485 10.4756L9.26035 8.08743L11.6487 5.69913L10.3912 4.44164L8.00286 6.82994L5.61452 4.4416L4.35703 5.69909L6.74537 8.08743L4.35719 10.4756L5.61468 11.7331L8.00286 9.34492Z" fill="#FF5959"/>
            </g>
            <defs>
                <clipPath id="clip0_3828_51537">
                    <rect width="16" height="16" fill="white"/>
                </clipPath>
            </defs>
        </svg>
        ${message}
    </span>`;

    $(field).find('.woocommerce-input-wrapper').after(error);
    field.addClass('error');
}

/**
 * Remove error form fields
 */
function removeErrors() {
    if ($('.mena-woocommerce-form__item').
        hasClass('error')) {
        $('.mena-woocommerce-form__item').
            removeClass('error');
        $('.mena-woocommerce-form__item-error').
            remove();
    }
}

/**
 * Check invalid zip codes
 */
function zipCodesValidator(testZipCode) {
    const zipData = menaObject.zipcodes_validator;
    let message = '';
    let zipCodes = '';

    if (zipData.error_message) {
        message = zipData.error_message;
    }

    if (zipData.zipcodes) {
        zipCodes = zipData.zipcodes;
        zipCodes = zipCodes.split(',');
        zipCodes = zipCodes.map(function (element) {
            return element.trim();
        });

        if (zipCodes.includes(testZipCode)) {
            return message;
        } else {
            return;
        }
    }
}

function containsNumbers(input) {
    const regex = /\d/;
    return regex.test(input);
}

export function saveBillingData() {
    let data = {};
    let ids = [];
    let globalTimeout;

    $('.mena-woocommerce-form__item input').each(function (i, el) {
        let id = el.id.slice(8);
        if (id) {
            ids.push(id);
            fillData(id);
        }
    });

    function fillData(id) {
        $(`#billing_${id}`).on('input paste', function () {
            clearTimeout(globalTimeout); // Clear the previous timeout

            $(ids).each(function (i, id) {
                let field = $(`#billing_${id}`);
                let inputValue = $(field).val();
                if (inputValue.length >= 2) {
                    data[id] = inputValue;
                }
            });
        });
    }
}

export function addDashToPostalCode() {
    $('#billing_postcode').on('keypress change', function () {
        let inputValue = $(this).val();

        if (inputValue.length >= 2 && inputValue.charAt(2) !== '-') {
            inputValue = inputValue.slice(0, 2) + '-' + inputValue.slice(2);
            $(this).val(inputValue);
        }

        let firstHyphenIndex = inputValue.split('-').length - 1;
        if (firstHyphenIndex > 1) {
            inputValue = inputValue.slice(0, firstHyphenIndex + 1) + inputValue.slice(firstHyphenIndex + 1).replace(/-/g, '');
            $(this).val(inputValue);
        }
    });
}

export function addSpaceToPostalCode(spaceLetterPosition = 3) {
    $('#billing_postcode').on('keypress change', function (e) {
        let inputValue = $(this).val();

        if (inputValue.length >= spaceLetterPosition && inputValue.charAt(spaceLetterPosition) !== ' ') {
            inputValue = inputValue.slice(0, spaceLetterPosition) + ' ' + inputValue.slice(spaceLetterPosition);
            $(this).val(inputValue);
        }
    });
}

export function limitOnAddress1() {
    const maxCharLabel = menaObject.labels.global.max_char;

    $('#billing_address_1').on('input', function () {
        let inputValue = $(this).val();
        let parent = $('#billing_address_1').closest('.mena-woocommerce-form__item ');

        if (inputValue.length > 40) {
            $(this).val(inputValue.substring(0, 40));

            if (($(parent).find('.mena-woocommerce-form__item-error')).length === 0) {
                showError($(parent), maxCharLabel.replace(/\{num\}/g, 35));
            }
        }
    });
}
