export default function () {
    let subscribeFilled = getCookie('subscribe_filled');
    let triggerSubscribeSession = sessionStorage.getItem('session_subscribe_show');

    if (!triggerSubscribeSession && !subscribeFilled && $('.home').length > 0) {
        setTimeout(() => {
            $('.js-subscribe-popup').addClass('active');
            sessionStorage.setItem('session_subscribe_show', true);
        }, 1000);
    }

    $('.c-popup .js-close-popup').on('click', function () {
        if ($('.c-popup .js-popup-checkbox').is(':checked')) {
            setCookie('subscribe_filled', true, 365);
        }

        $('.js-subscribe-popup').removeClass('active');
    });

    /**
     * Create new Cookie.
     */
    function setCookie(cname, cvalue, exdays) {
        const d = new Date();
        d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
        let expires = 'expires=' + d.toUTCString();
        document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
    }

    /**
     * Get existing Cookie.
     */
    function getCookie(cname) {
        let name = cname + '=';
        let ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return '';
    }
}
