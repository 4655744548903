import jackpotValidation from './jackpotValidation';
import addToOrder from './addToOrder';
import ProductModal from '../woocommerce/single-product/modal';
import { breadcrumb } from './jackpot';

export default class Jackpot {
    constructor(element) {
        this.container = element;
        this.winData = this.setWinValues();

        this.addEventListeners(this.container, this.triggerWinTransition, this.winData);
    }

    addEventListeners(container, winCallback, winData) {
        $(container).on('click', '.js-jackpot--spin-wheel', function (event) {
            event.preventDefault();
            if (!$(container).find(this).hasClass('spin')) {
                $(container).find(this).addClass('spin');
                $(container).find('.js-jackpot--win-options').addClass('spin');

                if ($('.js-jackpot--submit').hasClass('jackpot-not-used')) {
                    $('.js-jackpot--submit').removeClass('jackpot-not-used');
                }

                setTimeout(function () {
                    winCallback(winData);
                }, 2000);

                // Set jackpot wheel used meta for order
                $.ajax({
                    type: 'POST',
                    url : menaObject.ajaxurl,
                    data: {
                        action  : 'jackpot_wheel',
                        order_id: $('.js-jackpot--container').data('info'),
                    },
                });
            }
        });

        // Add to cart button
        $(container).on('click', '.jackpot--add-to-cart', function (event) {
            event.preventDefault();

            if ($('.page-template-jackpot').length > 0) {
                let productId = $(this).closest('.product-card').data('post-id');

                // Simple add to cart
                if ($(this).hasClass('type-simple')) {
                    addToOrder(productId);
                }

                // Open Modal
                if ($(this).hasClass('type-variable')) {
                    let modalType = 'special';

                    if ($('.js-jackpot--products-container').hasClass('jackpot')) {
                        modalType = 'jackpot';
                    }

                    let modal = new ProductModal(productId, modalType);

                    $('.js-product-modal--close').on('click', function () {
                        modal.destroy();
                    });
                }

                // Do animation
                productCardAnimation(productId);
            }
        });

        $(container).on('click', '.js-jackpot--submit, .js-jackpot--skip-cta', function (event) {
            event.preventDefault();

            var urlParams = new URLSearchParams(window.location.search);
            var orderKey = urlParams.get('jackpot');

            if (orderKey) {
                $.ajax({
                    url: menaObject.ajaxurl,
                    type: 'POST',
                    data: {
                        action: 'set_jackpot_as_used',
                        order_key: orderKey,
                    },
                    success: function(response) {
                        if(response){
                            location.href = response
                        }
                    },
                    error: function(errorThrown){
                        console.log('Error:', errorThrown);
                    }
                });
            }

            jackpotValidation();
        });
    }

    startTimer(duration, display) {
        let timer = duration;
        let minutes;
        let seconds;

        setInterval(function () {
            minutes = parseInt(timer / 60, 10);
            seconds = parseInt(timer % 60, 10);

            minutes = minutes < 10 ? '0' + minutes : minutes;
            seconds = seconds < 10 ? '0' + seconds : seconds;

            display.text(`${minutes}M ${seconds}S`);

            if (--timer < 0) {
                timer = duration;
            }
        }, 1000);
    }

    triggerWinTransition(winData) {
        setTimeout(function () {
            setTimeout(function () {
                $('.js-jackpot--container').css('margin-top', 0);
                $('.js-jackpot--main-wrapper').slideUp(300);
                $('.js-jackpot--intro').slideUp(300);
                $('.js-jackpot--products-wrapper').slideToggle(300);
                toggleJackpotModal();

                setTimeout(function () {
                    $('.js-jackpot--top').slideDown(300);
                    breadcrumb();
                }, 300);
            }, 2000);
        }, 350);
    }

    setWinValues() {
        let winData = {
            title   : $('.js-jackpot--win-title').val(),
            subtitle: $('.js-jackpot--win-subtitle').val(),
            amount  : $('.js-jackpot--win-amount').val(),
        };

        $('.js-jackpot--win-title').remove();
        $('.js-jackpot--win-subtitle').remove();
        $('.js-jackpot--win-amount').remove();

        return winData;
    }
}

function toggleJackpotModal() {
    const modal = $('.js-jackpot--modal-next-discord.jackpot__modal-next-discord--jackpot');
    $(modal).addClass('opened');

    setTimeout(function () {
        $(modal).removeClass('opened');
    }, 5000);
}

function productCardAnimation(product_id) {
    const productCard = $(`.product-card[data-post-id=${product_id}]`);

    // Add to cart animation
    if (!$(productCard).closest('.jackpot__product-card').hasClass('active')) {
        $(productCard).closest('.jackpot__product-card').addClass('active');

        setTimeout(() => {
            $(productCard).closest('.jackpot__product-card').removeClass('active');
        }, 9000);
    }
}
