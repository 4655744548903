import emptyCart from './emptyCart';
import headerCartAjax from './headerCartAjax';

export default function () {
    emptyCart();

    if ($('.woocommerce-checkout').length == 0) {
        headerCartAjax();
    }
}
