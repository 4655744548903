/**
 * Main
 */
import intro from './checkout/intro';
import handleAccordion from './checkout/handleAccordion';
import updateCart from './checkout/updateCart';
import addCrossSell from './checkout/addCrossSell';
import services from './checkout/thirdStep/services';
import customerTypeHandle from './checkout/thirdStep/customerTypeHandle';
import checkoutLabels from './checkout/thirdStep/checkoutLabels';
import reviewOrder from './checkout/thirdStep/reviewOrder';
import submitForm from './checkout/submitForm';
import checkoutCookie from './checkout/secondStep/checkoutCookie';
import jackpot, { deleteJackpotCookie, getJackpotCookie } from '../jackpot/jackpot';
import callCenter from './checkout/callCenter';

import {
    addDashToPostalCode,
    addSpaceToPostalCode,
    limitOnAddress1,
    saveBillingData,
} from './checkout/secondStep/checkBillingAddress';
import paketomat from './checkout/paketomat';

function redirectUserFromJackpot() {
    let url = getJackpotCookie();

    if (url) {
        window.location.href = url;
    }
}

/**
 * Checkout page
 */
export default function () {
    if (($('.js-thank-you--order').length > 0) && (getJackpotCookie())) {
        deleteJackpotCookie();
    }

    if ($('.checkout-accordion').length > 0) {
        intro();
        handleAccordion();
        updateCart();
        addCrossSell();
        services();
        customerTypeHandle();
        checkoutLabels();
        reviewOrder();
        submitForm();
        jackpot();
        limitOnAddress1();
        saveBillingData();
        checkoutCookie();
        redirectUserFromJackpot();
        callCenter();

        const $bodyLanguage = $('body').data('language');

        if ($bodyLanguage === 'pl') {
            addDashToPostalCode();
        }
        if ($bodyLanguage === 'cs' || $bodyLanguage === 'sk') {
            addSpaceToPostalCode();
        }

        if ($('.js-shipping--method').hasClass('paketomat')) {
            paketomat();
        }

        $('.select2 #billing_mena_state, .select2 #billing_city').select2({
            containerCssClass : 'mena-woocommerce-form__item--select-container',
            minimumInputLength: 2,
            language          : $('body').data('language'),
        });

    }

}
