import specialValidation from './specialValidation';

export default function () {
    if ($('.page-template-special').length > 0) {
        const queryString = window.location.search;

        if (queryString) {
            const urlParams = new URLSearchParams(queryString);
            const code = urlParams.get('special');

            $.ajax({
                type: 'POST',
                url: menaObject.ajaxurl,
                data: {
                    action: 'special_sidebar',
                    hash: code,
                },
                success: function (response) {
                    $('.js-special__products-sidebar-wrapper').html(response);
                    $('.js-special__products-sidebar-wrapper').removeClass('loading');

                    specialValidation();
                },
            });
        }
    }
}
