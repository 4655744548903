import variables from '../../../global/variables';

export default {
    getVariationsSelect: function () {
        return $('.js-variations--select');
    },

    initializeVariationSelect: function () {
        $('.js-variations--select').remove();
    },

    addAdditionaVariationsSelect: function (numberOfProducts) {
        for (let index = 1; index <= numberOfProducts; index++) {
            let newVariationSelect = $(variables.orderBoxVariationSelect).clone();

            $(newVariationSelect).find('.active').removeClass('active');
            $('.c-woo-variations').append(newVariationSelect);

            $(newVariationSelect).find('.js-variation--toggle:first-child').addClass('active');
        }
    },

    removeAdditionalVariationSelect: function (numberOfProducts) {
        let alreadyTakenProducts = $('.js-variations--select').length;

        if (alreadyTakenProducts > numberOfProducts) {
            let removedProducts = alreadyTakenProducts - numberOfProducts;

            $('.js-variations--select').slice(-removedProducts).remove();
            $('.c-woo-variations').off('click');
        }

        if (alreadyTakenProducts < numberOfProducts) {
            let addedProducts = numberOfProducts - alreadyTakenProducts;

            for (let index = 1; index <= addedProducts; index++) {
                let newVariationSelect = $(variables.orderBoxVariationSelect).clone();

                $(newVariationSelect).find('.active').removeClass('active');
                $('.c-woo-variations').append(newVariationSelect);

                $(newVariationSelect).find('.js-variation--toggle:first-child').addClass('active');
            }
        }
    },

    initializeListeners: function () {
        $('.c-woo-variations').on('click', '.js-variation--toggle', function () {
            $(this).siblings('.active').removeClass('active');
            $(this).addClass('active');
        });
    },
};
