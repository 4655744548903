import emptyCart from './emptyCart';
import { removeAllLocalStorage } from '../woocommerce/checkout/global';

export default function () {
    let hash;
    const queryString = window.location.search;

    if (queryString) {
        const urlParams = new URLSearchParams(queryString);
        hash = urlParams.get('abandoned');
    }

    if (!hash) {
        hash = localStorage.getItem('order_hash');
    }

    $.ajax({
        type      : 'POST',
        url       : menaObject.ajaxurl,
        data      : {
            action: 'header_cart',
            hash  : hash,
        },
        beforeSend: function () {
            $('.js-header--cart').addClass('loading');
            $('.js-header--cart-container').removeClass('active');
        },
        success   : function (response) {
            if (response) {
                response = JSON.parse(response);

                // If customer have order id, and that order is in trash or delete permanently
                if (response.without_order == 1) {
                    removeAllLocalStorage();
                }

                // Show header cart
                $('.js-header--cart').html(response.html);
                $('.js-header--cart').removeClass('loading');
                emptyCart();
            }
        },
    });
}
