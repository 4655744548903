export default function () {
    let searchInput = $('.js-products--search');
    let searchInputValue = $(searchInput).val();
    let termID = 0;

    $('.js-products--filter').on('click', function (e) {
        e.preventDefault();
        termID = $(this).data('term-id');

        $('.js-products--filter.active').removeClass('active');
        $('.js-products--filter[data-term-id="' + termID + '"]').addClass('active');
        if ($(this).data('name') == 'all') {
            $('.js-products--filter[data-name="all"]').addClass('active');
        }

        let filters = document.querySelector('.product-filters');
        filters.scrollIntoView(true);
        console.log(termID);
        checkByID(termID);
    });

    $(searchInput).on('keyup input paste', function () {
        let inputValue = $(searchInput).val().trim();

        if (inputValue.length > 0) {
            $('.filters__search').addClass('active');
        } else {
            $('.filters__search').removeClass('active');
        }

        if (inputValue.length > 2 || inputValue.length === 0) {
            searchInputValue = inputValue;

            checkByID(termID);
            checkBySearch(searchInputValue);
        }
    });
}

function checkByID(id) {
    if (id) {
        console.log(id);
        $('.js-products--card').hide(0);
        $('.js-products--card').addClass('hidden');

        $(`.js-products--card[data-category-id="${id}"]`).show(0);
        $(`.js-products--card[data-category-id="${id}"]`).removeClass('hidden');
    } else {
        $('.js-products--card').show(0);
        $('.js-products--card').removeClass('hidden');
    }
}

function checkBySearch(search) {
    $('.js-products--card').each(function (index, card) {
        if (!$(card).hasClass('hidden') && search.length >= 3) {
            let productTitle = String($(card).find('.product-card__title').text()).toLowerCase().trim();

            if (productTitle.includes(search)) {
                $(card).show(0);
                $(card).removeClass('hidden');
            } else {
                $(card).hide(0);
                $(card).addClass('hidden');
            }
        }
    });
}
