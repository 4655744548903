export default function () {
    let textPlural = menaObject.labels.global.pieces;
    let textSingular = menaObject.labels.global.piece;
    let html = `<span>${textPlural}</span>`;

    $('.quantity').each(function () {
        let type = $(this).siblings('.product_sell_type').data('type');
        let inputVal = $(this).find('input').val();

        if (inputVal) {
            inputVal = parseInt(inputVal);
        }

        if (inputVal === 1 && textSingular) {
            html = `<span>${textSingular}</span>`;
        }

        if (type === 'set') {
            $(this).append(`<span>set</span>`);
            return;
        }

        $(this).append(html);
    });
}
