import toggleUpsellLoader from '../../components/toggleUpsellLoader';
import edgeTagAddToCart from '../../edgetag/addToCart';

export default function () {
    insertSpans();

    //on update cart
    $(document.body).on('updated_cart_totals', function () {
        insertSpans();
    });

    $(document).on('click', '.js-mena-cart-cross-sell', function (e) {
        e.preventDefault();
        const upsellElement = $(this);

        if (upsellElement.hasClass('cross-sell-card__quantity')) {
            return false;
        }

        let step = null;

        if (upsellElement.closest($('.checkout-accordion__step')).hasClass('first')) {
            step = 'cart';
        } else if (upsellElement.closest($('.checkout-accordion__step')).hasClass('second')) {
            step = 'checkout';
        }

        $(upsellElement).addClass('adding');
        toggleUpsellLoader(upsellElement);
        $(upsellElement).removeClass('adding');
        let productId = upsellElement.data('post-id');

        if ($('.js-mena-cart-cross-sell[data-post-id="' + productId + '"]').hasClass('active')) {
            $.ajax({
                type   : 'POST',
                url    : menaObject.ajaxurl,
                data   : {
                    'action'    : 'woocommerce_ajax_remove_cross_sell_from_cart',
                    'product_id': productId,
                },
                success: function (response) {
                    $('.js-mena-cart-cross-sell[data-post-id="' + productId + '"]').removeClass('active');
                    if (response.success) {
                        $('#update-cart').click();
                    }
                    toggleUpsellLoader(upsellElement);
                },
                error  : function () {
                    toggleUpsellLoader(upsellElement);
                    alert('There was an error removing the product from the cart.');
                },
            });

        } else {
            $.ajax({
                type   : 'POST',
                url    : menaObject.ajaxurl,
                data   : {
                    'action'    : 'woocommerce_ajax_add_cross_sell_to_cart',
                    'product_id': productId,
                    'step'      : step,
                },
                success: function (response) {
                    $('.js-mena-cart-cross-sell[data-post-id="' + productId + '"]').addClass('active');
                    toggleUpsellLoader(upsellElement);
                    if (response.success) {

                        const product = {
                            id        : response.data.id,
                            quantity  : response.data.quantity,
                            item_price: response.data.item_price,
                            title     : response.data.title,
                            category  : response.data.category,
                            image     : response.data.image,
                            url       : response.data.url,
                            currency  : response.data.currency,
                        };
                        edgeTagAddToCart(product);
                        $('#update-cart').click();
                    }
                },
                error  : function () {
                    toggleUpsellLoader(upsellElement);
                    alert('There was an error adding the product to the cart.');
                },
            });
        }
    });

    // on change js-mena-cart-cross-sell select
    $(document).on('change', '.js-mena-cart-cross-sell select', function (e) {
        const productId = $(this).closest('.js-mena-cart-cross-sell').data('post-id');
        const quantity = $(this).val();

        let cartItemQty = $('.woocommerce-cart-form__cart-item[data-post-id="' + productId + '"] input.qty');
        let cartItemQtyValue = parseInt(cartItemQty.val());

        if (quantity !== cartItemQtyValue) {
            cartItemQty.val(quantity);
            $('#update-cart').click();
        }
    });

    $(document).on('click', '.js-mena-cart-cross-sell--remove', function (e) {
        e.preventDefault();
        const $this = $(this);
        let $selectQty = $this.siblings('.js-mena-cart-cross-sell').find('select');
        $selectQty.val(0);
        $selectQty.trigger('change');
    });
}

function insertSpans() {
    let $crossSellText = $('.cross-sell-card__desc');
    $crossSellText.each(function () {
        let $this = $(this);
        let generatedText = $this.text();
        $this.text('');

        const charNumber = 25;
        if (generatedText.length < charNumber) return;

        let dotsSpan = $('<span>').addClass('dots').text('...');
        let showMoreSpan = $('<span>').addClass('show-more').text(` ${menaObject.labels.global.show_more}`);
        let showLessSpan = $('<span>').addClass('show-less').text(` ${menaObject.labels.global.show_less}`);

        let firstText = generatedText.substring(0, charNumber);
        let restText = generatedText.substring(charNumber);
        restText = $('<span>').addClass('more').text(restText);

        $this.append(firstText);
        $this.append(dotsSpan);
        $this.append(showMoreSpan);
        $this.append(restText);
        $this.append(showLessSpan);

        // Show more/less functionality
        $this.find('.show-more').on('click', function () {
            $(this).hide();
            $(this).siblings('.more').show();
            $(this).siblings('.show-less').show();
            $(this).siblings('.dots').hide();
        });

        $this.find('.show-less').on('click', function () {
            $(this).hide();
            $(this).siblings('.more').hide();
            $(this).siblings('.show-more').show();
            $(this).siblings('.dots').show();
        });
    });
}

