export default {
    hideOnLoad: function () {
        if ($('.woocommerce-Reviews').length > 0) {
            $('.woocommerce-Reviews').css('display', 'none');
        }
    },
    addToggleButton: function () {
        if ($('.woocommerce-Reviews').length > 0) {
            $('.woocommerce-Tabs-panel--reviews').on('click', '.woocommerce-Tabs-panel--reviews-expand', function () {
                $('.woocommerce-Reviews').slideToggle(300);
            });
        }
    },
};
