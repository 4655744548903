import variables from '../../../global/variables';

export default {
    initializeSwiper: function () {
        if ($('.js-single-product--gallery-swiper').length > 0) {
            if (variables.swiperAssetsLoaded) {
                return createProductGallerySwiper();
            } else {
                loadjs([menaObject.assets + '/js/swiper.min.js', menaObject.assets + '/css/swiper.css'], function () {
                    return createProductGallerySwiper();
                });
            }
        }
    },
};

function createProductGallerySwiper() {
    let swiper = new Swiper('.js-single-product--gallery-swiper', {
        slidesPerView : 1,
        loop          : true,
        grabCursor    : false,
        allowTouchMove: false,
        pagination    : {
            el            : '.js-single-product--gallery-swiper-pagination',
            dynamicBullets: true,
        },
        navigation    : {
            nextEl: '.c-woo-product-image__next',
            prevEl: '.c-woo-product-image__prev',
        },
    });

    return swiper;
}
