import sidebar from './sidebar';
import destroyModal from './destroyModal';
import notices from '../woocommerce/notices/notices';
import qty_cart from '../woocommerce/checkout/updateCart';
import toggleLoader from '../components/toggleLoader';
import edgeTagAddToCart from '../edgetag/addToCart';

/**
 * Add product to order with jackpot prices
 * @param {Number} productId product ID
 * @param {String} type type of modal
 */
export default function (productId, type = '', quantity = 1) {
    $('.js-jackpot--modal-next-discord--close').on('click', destroyJackpotModal);

    $.ajax({
        type: 'POST',
        url: menaObject.ajaxurl,
        data: {
            action: 'jackpot_add_to_order',
            order_id: $('.js-jackpot--container').data('info'),
            product_id: productId,
            products_type: $('.js-jackpot--products').data('type'),
            quantity: quantity,
        },
        beforeSend: function () {
            $('.js-jackpot__products-sidebar-wrapper').addClass('loading');

            if (type == 'jackpot') {
                $('.js-c-product-modal--loader, .single-product').toggleClass('hidden');
            }

            if (!$('.js-jackpot__products-sidebar-wrapper').hasClass('opened')) {
                setTimeout(() => {
                    $('.js-jackpot__products-sidebar-wrapper').addClass('opened');
                }, 5500);
            }

            // toggleLoader();
        },
        success: function (response) {
            // edgeTagAddToCart()
            notices();

            if (response) {
                response = JSON.parse(response);

                setTimeout(() => {
                    $('.js-jackpot--products-container').html(response);
                    $('.js-jackpot--products').data('type', 'special');
                    toggleMiddleModal()
                    // $('.js-jackpot--modal-next-discord.jackpot__modal-next-discord--special').addClass('opened');
                    // setTimeout(destroyJackpotModal, 5000);

                    if ($('.js-jackpot--products-container').hasClass('jackpot')) {
                        $('.js-jackpot--breadcrumb:nth-child(3), .js-jackpot--products-container, .js-jackpot--products, .jackpot__top-timer-svg, .js-jackpot--submit').removeClass('jackpot');
                        $('.js-jackpot--breadcrumb:nth-child(3), .js-jackpot--products-container, .js-jackpot--products, .jackpot__top-timer-svg, .js-jackpot--submit').addClass('special');
                        // toggleLoader();
                        $('.js-jackpot--products-wrapper.jackpot__products-wrapper--jackpot').css('background-image', "url('" + menaObject.assets + "/images/jackpot/modal-special-bg.jpg')");
                    }
                }, 11000);
            }

            if (type == 'jackpot') {
                $('.js-c-product-modal--loader, .single-product').toggleClass('hidden');
            }

            // $('.js-jackpot__products-sidebar-wrapper').removeClass('loading');

            // setTimeout(() => {
            //     $('.js-jackpot__products-sidebar-wrapper').removeClass('opened'); // Close sidebar
            // }, 11000);

            setTimeout(() => {
                sidebar();
                if ($('.js-jackpot--products-container').hasClass('special')) {
                    const urlParams = new URLSearchParams(window.location.search)
                    const order_key = urlParams.get('jackpot')
                    const order_id = $('.jackpot.js-jackpot--container').attr('data-info')

                    if (order_key && order_id) {
                        const url = menaObject.checkout_url + `order-received/${order_id}/?key=${order_key}`
                    } else {
                        console.log({
                            order_id,
                            order_key
                        })
                        console.error('Couln\'t get order id and order key')
                    }

                    // toggleLoader();
                }
            }, 1000);

            destroyModal();
        },
    });
}

// CLOSES ULTRA JACKPOT MODAL
function destroyJackpotModal() {
    $('.js-jackpot--modal-next-discord').removeClass('opened');
}


function toggleMiddleModal() {
    const modal = $('.js-jackpot--modal-middle');
    $(modal).addClass('opened');
}