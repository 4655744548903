import form from './reviews/form';
import stars from './reviews/stars';
import orderBox from './order-box/order-box';
import variables from '../../global/variables';
import gallery from './gallery/gallery';
import tabs from './tabs';
import share from './share';
import './comments';

export default {

    initialize: function () {
        form.hideOnLoad();
        form.addToggleButton();

        variables.productGallerySwiper = gallery.initializeSwiper();

        if ($('.comment-form').length > 0) {
            $('.comment-form').on('click', '.stars a', function () {
                stars.updateStars($(this));
            });
        }

        if ($('.single-product .wc-tabs').length > 0) {
            tabs.initializeListeners();
        }

        if ($('.js-single-product--share').length > 0) {
            $('.js-single-product--share').on('click', function (event) {
                event.preventDefault();
                share.triggerShare(share.getCurrentDevice());
            });
        }

        if ($(window).width() < 1340) {
            // Get content in variable
            let rightSide = $('.single-product__right');

            // Remove from content
            $('.single-product__right').remove();

            // Append on another place
            rightSide.insertAfter('.c-woo-product-image');
        }

        orderBox.initializeOrderBox();
    },
};
