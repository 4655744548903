import checkBillingAddress from './secondStep/checkBillingAddress';

export function removeAllLocalStorage() {
    localStorage.removeItem('order');
    localStorage.removeItem('status');
    localStorage.removeItem('order_hash');
}

export function changeCheckoutFormData() {
    $('form.checkout.woocommerce-checkout').on('keydown', function (event) {
        if (event.key === 'Enter') {
            event.preventDefault();
            checkBillingAddress();
        }
    });

    $(document).on('checkout_error', function (event) {
        if ($('.checkout-accordion').hasClass('loading')) {
            $('.checkout-accordion').removeClass('loading');
        }

        if (!localStorage.getItem('order_hash')) {
            localStorage.setItem('order_hash', $('#billing_mena_order').val());
        }

        checkBillingAddress();
        console.log('error');
    });

    $(document).on('stripeError', function (event) {
        if ($('.checkout-accordion').hasClass('loading')) {
            $('.checkout-accordion').removeClass('loading');
        }

        if ($('.wc-payment-form')) {
            $('.wc-payment-form').slideDown();
        }

        if (!localStorage.getItem('order_hash')) {
            localStorage.setItem('order_hash', $('#billing_mena_order').val());
        }

        checkBillingAddress();
        console.log('error');
    });
}
