/**
 * Revert minuts timer
 * @param {Number} minutesToAdd
 */
export default function (minutesToAdd) {
    if ($('.js-time-left').length == 0) {
        return;
    }

    runTimer();

    setInterval(function () {
        runTimer();
    }, minutesToAdd * 60000 - 1000);

    function runTimer() {
        // Set the date we're counting down to
        let currentDate = new Date();
        let countDownDate = new Date(currentDate.getTime() + minutesToAdd * 60000);

        // Update the count down every 1 second
        let x = setInterval(function () {
            // Get today's date and time
            let now = new Date().getTime();

            // Find the distance between now and the count down date
            let distance = countDownDate - now;

            // Time calculations for minutes and seconds
            let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor((distance % (1000 * 60)) / 1000);

            // Display the result in the element
            let output = minutes + 'm, ' + seconds + 'S.';
            document.querySelector('.js-time-left').innerHTML = output;

            // If the count down is finished, clear it
            if (distance < 2) {
                clearInterval(x);
            }
        }, 1000);
    }
}
