import variables from '../../../global/variables';

export default function () {
    let minimumPrice = Number($('.js-order-box--max-price').data('minimum-price'));
    let currentTotalPrice = 0;
    let fullDiscountPrice;
    let quantity = Number(variables.addToCartObject.quantity);

    if (quantity === 0) {
        quantity = 1;
    }

    variables.addToCartObject.totalPrice = 0;

    if (variables.addToCartObject.cartPrice) {
        currentTotalPrice += variables.addToCartObject.cartPrice;
        variables.addToCartObject.totalPrice += variables.addToCartObject.cartPrice;
    }

    if (variables.addToCartObject.quantityPrice) {
        currentTotalPrice += variables.addToCartObject.quantityPrice;
        variables.addToCartObject.totalPrice += variables.addToCartObject.quantityPrice;
    }

    if (variables.addToCartObject.crossSellPrice) {
        currentTotalPrice += variables.addToCartObject.crossSellPrice;
        variables.addToCartObject.totalPrice += variables.addToCartObject.crossSellPrice;
    }

    $('.js-order-box--current-price').text(String(Number(currentTotalPrice).toFixed(2)) + $('.js-order-box--currency-label').val());
    $('.js-order-box--total-price').text(String(Number(variables.addToCartObject.quantityPrice.toFixed(2)).toFixed(2)) + $('.js-order-box--currency-label').val());

    let getFirstRegularPrice = Number($('.c-woo-header__price').data('full-price'));
    if (getFirstRegularPrice === 0) {
        getFirstRegularPrice = 1;
    }

    fullDiscountPrice = Number(getFirstRegularPrice * quantity - variables.addToCartObject.quantityPrice);
    if (fullDiscountPrice > 0) {
        $('.js-order-box--discount-price').text(String(Number(fullDiscountPrice).toFixed(2)) + $('.js-order-box--currency-label').val());

        if ($('.c-woo-order-box-footer__totals').hasClass('disable')) {
            $('.c-woo-order-box-footer__totals').removeClass('disable');
        }
    } else {
        $('.c-woo-order-box-footer__totals').addClass('disable');
    }

    if (menaObject.multi_currency.multi_currency) {
        $('.js-order-box--current-price').append(multiCurrencyCalc(Number(currentTotalPrice).toFixed(2)));
    }

    let percentage = (currentTotalPrice / minimumPrice) * 100;

    if (percentage >= 100) {
        percentage = 100;
        if (!$('.js-order-box--progress-bar').hasClass('active')) {
            $('.js-order-box--progress-bar').addClass('active');
        }
    } else {
        if ($('.js-order-box--progress-bar').hasClass('active')) {
            $('.js-order-box--progress-bar').removeClass('active');
        }
    }

    $('.js-order-box--progress-bar').attr('style', `width: ${percentage}%`);
}

/**
 * Convert second currency price
 * @param {String} price Price for convert
 * @return {String} Html for display second currency price
 */
function multiCurrencyCalc(price) {
    // Prepare all needed data
    const data = menaObject.multi_currency;
    const rate = Number(data.price).toFixed(2);
    const operator = data.operator;
    const currency = data.currency;
    let returnPrice;

    // Switch operators and calculate return price
    if (rate && operator && currency) {
        switch (operator) {
        case '*':
            returnPrice = price * rate;
            break;
        case '/':
            returnPrice = price / rate;
            break;
        case '+':
            returnPrice = price + rate;
            break;
        case '-':
            returnPrice = price - rate;
            break;
        }

        // Prepare for display
        let output = document.createElement('span');
        output.className = 'multi-currency-price';
        output.innerHTML = '(' + Number(returnPrice).toFixed(2) + ' ' + currency + ')';
        return output;
    }
}
