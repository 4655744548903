export default function () {
    if ($('.page-template-jackpot').length > 0) {
        toggleJackpot();

        $.ajax({
            type: 'POST',
            url: menaObject.ajaxurl,
            data: {
                action: 'jackpot_sidebar',
                order: $('.js-jackpot--container').data('info'),
            },
            beforeSend: function () {
                // setting a timeout
                $('.js-jackpot__products-sidebar-wrapper').addClass('loading');
            },
            success: function (response) {
                if (response) {
                    response = JSON.parse(response);

                    if (response.redirect) {
                        window.location.replace(response.redirect);
                    } else {
                        $('.js-jackpot__products-sidebar-wrapper').removeClass('loading');
                        $('.js-jackpot__products-sidebar-wrapper').html(response.sidebar);
                        toggleJackpot();

                        setTimeout(() => {
                            $('.js-jackpot__products-sidebar-wrapper').removeClass('opened')
                        }, 9000)
                    }
                }
            },
        });
    }
}

function toggleJackpot() {
    $('.js-jackpot-sidebar-toggle').on('click', function () {
        if (!$('.jackpot__products-sidebar-wrapper').hasClass('opened')) {
            setTimeout(function () {
                $('.jackpot__products-sidebar-wrapper').addClass('opened');
                $(this).closest('.site-container').addClass('jackpot-active');
                $('body').addClass('jackpot-active');
            }, 3500);
        } else {
            $('.jackpot__products-sidebar-wrapper').removeClass('opened');
            $(this).closest('.site-container').removeClass('jackpot-active');
            $('body').removeClass('jackpot-active');
        }
    });
}
