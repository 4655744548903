import variables from '../../../global/variables';
import calculateFreeShipping from './calculate';

export default {
    initializeListeners: function () {
        $('.single-product .js-order-box--cross-sell').on('click', function () {
            $(this).toggleClass('active');

            if ($(this).hasClass('active')) {
                variables.addToCartObject.crossSellPrice += Number($(this).data('price'));
            } else {
                variables.addToCartObject.crossSellPrice -= Number($(this).data('price'));
            }

            calculateFreeShipping();
        });
    },
};
