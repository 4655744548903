import variables from '../../../global/variables';
import calculateFreeShipping from './calculate';

export default {
    getOrderPrice: () => {
        if ($('body').hasClass('single-product')) {
            $.ajax({
                type    : 'POST',
                url     : menaObject.ajaxurl,
                dataType: 'html',
                data    : {
                    action: 'woocommerce_order_box_get_order_price',
                    hash  : variables.hash,
                },

                beforeSend: function () {
                },

                success: function (response) {
                    let selectedQuantity = $('.js-order-box--price-select.selected');
                    response = JSON.parse(response);

                    variables.addToCartObject.cartPrice = Number(response.price);
                    variables.addToCartObject.quantityPrice = Number($(selectedQuantity).data('price')) * Number($(selectedQuantity).data('count'));

                    if (variables.addToCartObject.cartPrice > 0) {
                        calculateFreeShipping();
                    } else {
                        $('.js-order-box--progress-bar').attr('style', `width: 0%`);
                        $('.js-order-box--current-price').text('0' + $('.js-order-box--currency-label').val());
                    }
                },
            });
        }
    },

    getCartPrice: () => {
        $.ajax({
            type    : 'POST',
            url     : menaObject.ajaxurl,
            dataType: 'html',
            data    : {
                action: 'woocommerce_order_box_get_cart_price',
            },

            beforeSend: function () {
            },

            success: function (response) {
                let selectedQuantity = $('.js-order-box--price-select.selected');
                response = JSON.parse(response);

                variables.addToCartObject.cartPrice = Number(response.price);
                variables.addToCartObject.quantityPrice = Number($(selectedQuantity).data('price')) * Number($(selectedQuantity).data('count'));
                calculateFreeShipping();
            },
        });
    },
};
