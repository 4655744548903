export default function () {
    let debounce;
    let body = $('body');

    $(window).on('scroll', function () {
        //clear the delay if it's not finished yet
        if (debounce) clearTimeout(debounce);

        //start a new delay
        debounce = setTimeout(function () {
            //remove reference so another delay can start
            debounce = null;

            if ($(window).scrollTop() > 5) {
                if (!body.hasClass('sticky-menu')) {
                    body.addClass('sticky-menu');

                    if (!body.hasClass('page-template-jackpot')) {
                        $('.header-top').slideUp(300);
                        $('.header-bar').slideUp(300);
                        $('.header__form form').slideUp(300);
                        $('.js-header-cart--label').slideUp(300);
                    }
                }
            } else {
                body.removeClass('sticky-menu');

                if (!body.hasClass('page-template-jackpot')) {
                    $('.header-top').slideDown(300);
                    $('.header-bar').slideDown(300);

                    $('.header__form form').slideDown(300);
                    $('.js-header-cart--label').slideDown(300);
                }
            }
        }, 300);
    });
}
