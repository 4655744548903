/**
 * SEND INITIATE CHECKOUT EVENT TO EDGETAG
 */

function sendInitiateCheckout() {
    $.ajax({
        type: 'POST',
        url: menaObject.ajaxurl,
        data: {
            action: 'prepare_edgetag_checkout_data',
        },
        success: function (response) {
            if (response) {
                const checkout = JSON.parse(response);
                edgetag('tag', 'InitiateCheckout', checkout);
            }
        },
    });
}

export default function () {
    console.log(menaObject.edgetag);
    if (menaObject.edgetag) {
        console.log('edgetag True');
        if($('body').hasClass('woocommerce-checkout')) {
            if($('.js-checkout-accordion.second').hasClass('active')) {
                sendInitiateCheckout();
            }
            $(document).on('click', '.first-step__submit', function() {
                sendInitiateCheckout();
            });
        }
    }
}
