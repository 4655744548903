import jackpotValidation from './jackpotValidation';

/**
 * Revert seconds timer
 */
export default function () {
    if ($('.js-time-left--jackpot').length == 0) {
        return;
    }

    let time = $('.js-time-left--jackpot').data('timeout');
    runTimer(time);
}

/**
 * Convert seconds to minutes and seconds and display it every sec
 * @param {Int} secondsToAdd
 */
function runTimer(secondsToAdd) {
    // Set the date we're counting down to
    let currentDate = new Date();
    let countDownDate = new Date(currentDate.getTime() + secondsToAdd * 1000);
    let widthPercent = 100;

    // Update the count down every 1 second
    let x = setInterval(function () {
        // Get today's date and time
        let now = new Date().getTime();

        // Find the distance between now and the count down date
        let distance = countDownDate - now;

        // Prepare width for progress bar
        let allDistanceInSecunds = distance / 1000;
        allDistanceInSecunds = allDistanceInSecunds.toFixed(0);
        let distencInPercent = 100 - (allDistanceInSecunds / menaObject.jackpot_left_time) * 100;
        distencInPercent = distencInPercent.toFixed(0);

        if (distencInPercent !== widthPercent) {
            widthPercent = distencInPercent;
            $('.js-time-left--jackpot-progress-bar').css('width', distencInPercent + '%');
        }

        // Time calculations for minutes and seconds
        let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);

        // Display the result in the element
        let output = minutes + 'm, ' + seconds + 'S!';
        $('.js-time-left--jackpot').text(output);

        let secondsLeft = Math.floor(distance / 1000);

        // If the count down is finished, redirect on payment or thankyou page
        if (secondsLeft <= 2) {
            jackpotValidation();
        }

        // If the count down is finished, clear it
        if (secondsLeft < 1) {
            clearInterval(x);
        }
    }, 1000);
}
