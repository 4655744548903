/**
 * Toggle loader to body
 */
export default function () {
    if ($('.js-loader.main-cart-loader').length === 0) {
        showLoader();
    } else {
        closeLoader();
    }
}

/**
 * Show loader
 */
function showLoader() {
    const loader = `<div class="main-loader main-cart-loader js-loader">
                                 <div class="sk-circle">
                                      <div class="sk-circle1 sk-child"></div>
                                      <div class="sk-circle2 sk-child"></div>
                                      <div class="sk-circle3 sk-child"></div>
                                      <div class="sk-circle4 sk-child"></div>
                                      <div class="sk-circle5 sk-child"></div>
                                      <div class="sk-circle6 sk-child"></div>
                                      <div class="sk-circle7 sk-child"></div>
                                      <div class="sk-circle8 sk-child"></div>
                                      <div class="sk-circle9 sk-child"></div>
                                      <div class="sk-circle10 sk-child"></div>
                                      <div class="sk-circle11 sk-child"></div>
                                      <div class="sk-circle12 sk-child"></div>
                                </div>
                    </div>`;

    const cartBody = $('.checkout-first-step__form');
    cartBody.append(loader);
    $('.js-loader.main-cart-loader').addClass('loading');
}

/**
 * Remove Loader from body
 */
function closeLoader() {
    $('.js-loader.main-cart-loader').remove();
}
