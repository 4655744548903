export default function () {
    if ($('section.products, section.related, .page-template-jackpot, .page-template-special').length > 0) {
        let noticeText = menaObject.notices.add_to_cart;
        let noticeBox = `<div class="mena-wc-notices">
                            <p class="mb-0">${noticeText}</p>
                            <span class="mena-wc-notices__close"></span>
                        </div>`;

        $('.main').append(noticeBox);
    }
}
