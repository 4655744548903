export default function () {
    $('.js-special--submit').on('click', function (e) {
        e.preventDefault();
        const queryString = window.location.search;

        if (queryString) {
            const urlParams = new URLSearchParams(queryString);
            const code = urlParams.get('special');

            $.ajax({
                type: 'POST',
                url: menaObject.ajaxurl,
                data: {
                    action: 'special_validation',
                    hash: code,
                },
                beforeSend: function () {
                    $('.page-template-special').addClass('loading');
                },
                success: function (response) {
                    if (response) {
                        window.location.replace(response);
                    } else {
                        $('.page-template-special').removeClass('loading');
                    }
                },
            });
        }
    });
}
