import ProductModal from '../woocommerce/single-product/modal';
import addToOrder from './addToOrder';

export default function () {
    if ($('.page-template-special').length > 0) {
        $('.js-special--cta').on('click', function (e) {
            e.preventDefault();
            const productId = $(this).closest('.product-card').data('post-id');

            if ($(this).hasClass('type-simple')) {
                const queryString = window.location.search;

                if (queryString) {
                    const urlParams = new URLSearchParams(queryString);
                    const code = urlParams.get('special');
                    addToOrder(productId, code);
                }
            }

            // Open Modal
            if ($(this).hasClass('type-variable')) {
                let modal = new ProductModal(productId, 'special');

                $('.js-product-modal--close').on('click', function () {
                    modal.destroy();
                });
            }
        });
    }
}
