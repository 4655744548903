export default function () {
    let headerProductsBox = '<div class="header__form-products-wrapper"><div class="header__form-products"></div><span class="header__form-products-close"></span></div>';
    let searchForm = $('form.search-form');

    if (searchForm.length > 0) {
        searchForm.append(headerProductsBox);

        if ($('.header__form-products').length > 0) {
            let searchInput = $('.js-search-products');

            /* Handling when user starts typing */
            let ajax = true;
            $(searchInput).on('input paste', function () {
                let val = $('.js-search-products').val().trim();
                if (val.length == 0) {
                    removeBox();
                }

                setTimeout(function () {
                    if (val.length > 1) {
                        //for checking 2 characters
                        if (ajax == true) {
                            $.ajax({
                                type: 'GET',
                                url: menaObject.ajaxurl,
                                dataType: 'html', // add data type
                                data: {
                                    action: 'header_search_products',
                                    search: val,
                                },

                                beforeSend: function () {
                                    ajax = false;
                                },

                                success: function (response) {
                                    ajax = true;

                                    if (response) {
                                        response = JSON.parse(response);

                                        if (response != null) {
                                            $('.header__form-products').html(response.products);
                                            $('.header__form-products-wrapper').addClass('active');
                                        }
                                    }
                                },
                            });
                        }
                    }
                }, 1500);
            });

            /* Remove box */
            $('.header__form-products-close').on('click', removeBox);

            /* Work on submit */
            $('.search-form__cta').on('click submit', function (e) {
                e.preventDefault();

                let val = $('.js-search-products').val().trim();

                if (val.length > 0) {
                    //for checking 2 characters
                    if (ajax == true) {
                        $.ajax({
                            type: 'GET',
                            url: menaObject.ajaxurl,
                            dataType: 'html', // add data type
                            data: {
                                action: 'header_search_products',
                                search: val,
                            },

                            beforeSend: function () {
                                ajax = false;
                            },

                            success: function (response) {
                                if (response) {
                                    response = JSON.parse(response);

                                    $('.header__form-products').html(response.products);
                                    $('.header__form-products-wrapper').addClass('active');
                                    ajax = true;
                                }
                            },
                        });
                    }
                }
            });
        }
    }
}

function removeBox() {
    $('.header__form-products').empty();
    $('.header__form-products-wrapper').removeClass('active');
    $('.js-search-products').val('');
}
