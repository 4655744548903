import sidebar from './sidebar';
import notices from '../woocommerce/notices/notices';
import destroyModal from '../jackpot/destroyModal';

/**
 * Add product to order with special prices
 * @param {Number} productId product ID
 * @param {String} code hash code
 */
export default function (productId, code, type = '', quantity = 1) {
    $.ajax({
        type: 'POST',
        url: menaObject.ajaxurl,
        data: {
            action: 'special_products',
            productId: productId,
            hash: code,
            quantity: quantity,
        },
        beforeSend: function () {
            $('.js-special__products-sidebar-wrapper').addClass('loading');

            if (type == 'special') {
                $('.js-c-product-modal--loader, .single-product').toggleClass('hidden');
            }
        },
        success: function () {
            sidebar();
            notices();

            if (type == 'special') {
                $('.js-c-product-modal--loader, .single-product').toggleClass('hidden');
            }

            destroyModal();
        },
    });
}
