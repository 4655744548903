import toggleCartLoader from '../../components/toggleCartLoader';
import toggleCheckoutSidebarLoader from '../../components/toggleCheckoutSidebarLoader';
import { copyPaketomatFieldValues } from './paketomat';
/**
 *  Ajax call for update-cart.php
 * @param {String} item_hash cart item key
 * @param {Number} currentVal current quantity
 * @param {Number} crossSell cross sell product id
 * @param {Array} service additional service name and price
 * @param {String} email step one email submit
 * @param {Object} address object with step three form data
 * @param {String} payment payment method
 */

export function updateShippingMethods() {

    $.ajax({
        type: 'POST',
        url: menaObject.ajaxurl,
        data: {
            'action': 'reload_shipping_methods'
        },
        success: function () {
            console.log('Shipping methods reloaded');
        },
    });

}

function updateCheckoutOnChange() {
    $.ajax({
        type: 'POST',
        url: menaObject.ajaxurl,
        data: {
            'action': 'reload_checkout_review_box'
        },
        beforeSend: function () {
            toggleCheckoutSidebarLoader();
        },
        success: function (response) {
            $('.js-mena-sidebar-handler').html(response);
            var content = $('.js-sidebar-total-price > .woocommerce-Price-amount').html();
            $('.js-third-step-total > .woocommerce-Price-amount').html(content);
            $('.js-cart-shipping-bar').empty();
            let clonedElement = $('.js-shipping-bar').clone();
            $('.js-cart-shipping-bar').append(clonedElement);
        },
        completed: function () {
            toggleCheckoutSidebarLoader();
        },
    });
}

export async function updateCheckout() {
    $('body').on('payment_method_selected', await function(){
        $('body').trigger('update_checkout');
    });
    $('body').on('updated_checkout', await function(){
        updateCheckoutOnChange();
    });
    updateCheckoutOnChange();
    if($('.mena-cart__products').children().length === 0) location.href = menaObject.homeurl
}

export default function () {

    $(document).ready(function () {
        let clonedElement = $('.js-shipping-bar').clone();
        $('.js-cart-shipping-bar').append(clonedElement);
    });

    $(document).on('click', '.first-step__submit', function (e) {
        $('#update-cart').click();
    });

    $(document).on('click', '#update-cart', function (e) {
        e.preventDefault();
        toggleCartLoader();
        var cart = $('form.woocommerce-cart-form');
        var data = cart.serialize();

        var cartTab = $('.js-checkout-accordion.first').hasClass('active') ? 'true' : 'false';

        data += '&cartTab=' + cartTab;

        $.ajax({
            type: 'POST',
            url: menaObject.ajaxurl,
            data: data + '&action=ajax_cart_update',
            success: function (response) {
                $('.js-mena-cart-handler').html(response);
                $(document.body).trigger('updated_cart_totals');
                updateCheckout();
            },
            complete: function () {
                $('.first-step__submit').removeAttr('disabled');
                $('.first-step__submit').removeClass('disabled');
                toggleCartLoader();
            }
        });

    });

    function updateCartOnChange() {
        jQuery('.first-step__submit').attr('disabled', 'disabled');
        jQuery('.first-step__submit').addClass('disabled');
        setTimeout(function () {
            $('#update-cart').click();
        }, 500);
    }

    $(document).on('click', '.quantity .minus', function () {
        var $input = $(this).siblings('.qty');
        var value = parseInt($input.val(), 10);
        value = isNaN(value) ? 1 : value;
        value--;
        value = Math.max(value, 0);
        $input.val(value);
        updateCartOnChange();
    });

    $(document).on('click', '.quantity .plus', function () {
        var $input = $(this).siblings('.qty');
        var value = parseInt($input.val(), 10);
        value = isNaN(value) ? 0 : value;
        value++;
        $input.val(value);
        updateCartOnChange();
    });

    $(document).on('change', '.qty', function () {
        var value = parseInt($(this).val(), 10);
        if (isNaN(value) || value < 1) {
            $(this).val(1);
        }
        updateCartOnChange();
    });

    $(document).on('click', '.js-product-remove', function (e) {
        e.preventDefault();

        if ($(this).hasClass('js-product-remove-cross-sell')) {
            var crossSellId = $(this).data('cross-id');

            $.ajax({
                type: 'POST',
                url: menaObject.ajaxurl,
                data: {
                    'action': 'woocommerce_ajax_remove_cross_sell_from_cart',
                    'product_id': crossSellId,
                },
                success: function (response) {
                    $('.js-mena-cart-cross-sell[data-post-id="' + crossSellId + '"]').removeClass('active');
                    if (response.success) {
                        $('#update-cart').click();
                    }
                },
                error: function () {
                    alert('There was an error removing the product from the cart.');
                },
            });
        } else {

            var productId = $(this).data('product-id');

            var matchingParentElements = $('.js-product-remove').filter(function() {
                return $(this).data('product-id') === productId;
            });

            var matchingCrossElements = $('.js-product-remove').filter(function() {
                return $(this).data('main-product') === productId;
            });

            if (matchingParentElements.length === 1) {
                if (matchingCrossElements.length > 0) {
                    matchingCrossElements.each(function() {
                        var closestCartRow = $(this).closest('.mena-cart__row');
                        closestCartRow.find('input.input-text.qty').val(0);
                    });
                }
            } else {
                console.log(matchingParentElements.length);
            }

            var $cartRow = $(this).closest('.mena-cart__row');

            $cartRow.find('input.input-text.qty').val(0);
            updateCartOnChange();
        }
    });

    $(document).on('click', '.woocommerce-remove-coupon', function (e) {
        e.preventDefault();

        var couponCode = $(this).data('coupon');

        $.ajax({
            type: 'POST',
            url: menaObject.ajaxurl,
            data: {
                'action': 'ajax_remove_coupon_from_checkout',
                'coupon': couponCode,
            },
            success: function (response) {
                if (response.success) {
                    $('#update-cart').click();
                }
            },
            error: function () {
                alert('There was an error removing the coupon from checkout.');
            },
        });

    });

}
