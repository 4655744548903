import variables from "../../../global/variables";

export default {
    validateOrderBoxInput: function() {
        let variationSelectComponents = $('.js-variations--select').toArray();
        
        variables.orderBoxValidationObject = {
            valid: true,
            messages: [],
        }
        
        variationSelectComponents.forEach(function(variationSelect) {
            if($(variationSelect).find('.js-variation--toggle.active').length == 0) {
                variables.orderBoxValidationObject.valid = false;
                variables.orderBoxValidationObject.messages.push('Variable product is not selected!');
            }
        });

        $('.js-order-box--validation-messages').children().remove();

        variables.orderBoxValidationObject.messages.forEach(function(message) {
            $('.js-order-box--validation-messages').append(`
                <li>${message}</li>
            `);
        });
    }
}