import notices from '../notices/notices';
import ProductModal from '../single-product/modal';
import edgeTagAddToCart from '../../edgetag/addToCart';

export default function () {
    $('.js-product-card--cta').on('click', function (event) {
        // Add to cart SIMPLE
        if ($(this).hasClass('type-simple')) {
            event.preventDefault();

            $(this).addClass('loading');

            let product_id = $(this).closest('.js-products--card').data('post-id');
            let priceWithCurrency;

            if ($(this).siblings('.product-card__price').find('.product-card__price-sale').length > 0) {
                priceWithCurrency = $(this).siblings('.product-card__price').find('.product-card__price-sale .woocommerce-Price-amount').text();
            } else {
                priceWithCurrency = $(this).siblings('.product-card__price').find('.product-card__price-regular .woocommerce-Price-amount').text();
            }

            let priceOnly = priceWithCurrency.replace(/[^\d.,]/g, '');
            let priceFormatted = priceOnly.replace(/,/g, '.');
            let priceAsFloat = parseFloat(priceFormatted);

            let productTitle = $(this).siblings('.product-card__desc').find('.product-card__title').text();

            let productImageContainer = $(this).closest('.product-card__wrapper').find('.product-card__image');
            let imgSrc = productImageContainer.find('img').attr('src');

            $.ajax({
                type    : 'POST',
                url     : menaObject.ajaxurl,
                dataType: 'json',
                data    : {
                    action              : 'woocommerce_order_box_add_to_cart',
                    product_id          : product_id,
                    simple_product_count: 1,
                },
                success : function (response) {

                    let product = {
                        id        : product_id,
                        variantIds: [],
                        quantity  : 1,
                        item_price: priceAsFloat,
                        title     : productTitle,
                        category  : response.product_categories,
                        image     : imgSrc,
                        url       : response.product_url,
                        currency  : response.currency,
                    };

                    edgeTagAddToCart(product);
                    setTimeout(function () {
                        $('.js-product-card--cta').removeClass('loading');
                        notices();
                        window.location.href = response.url;
                    }, 1500);
                },
            });
        }

        // VARIATION
        if (!$(this).closest('section.products').length > 0) {
            // Open Modal
            if ($(this).hasClass('type-variable')) {
                event.preventDefault();
                let productId = $(this).closest('.js-products--card').data('post-id');
                let modal = new ProductModal(productId);
                $('.js-product-modal--close').on('click', function () {
                    modal.destroy();
                });
            }
        }
    });
}
