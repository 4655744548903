import variables from './global/variables';
import handleCookie from './global/handleCookie';
import subscribePopup from './popup/subscribe-popup';
import backToTop from './scroll/back-to-top';
import products from './woocommerce/products';
import faq from './blocks/faq';
import singleProduct from './woocommerce/single-product';
import jackpot from './jackpot/jackpot';
import Jackpot from './jackpot';
import timer from './woocommerce/checkout/timer';
import payments from './woocommerce/payments/payments';
import special from './special/special';
import headerCart from './header/headerCart';
import mobileMenu from './header/mobile-menu';
import stickyHeader from './header/sticky-header';
import productCard from './woocommerce/components/product-card';
import createNotices from './woocommerce/notices/createNotices';
import headerSearch from './header/headerSearch';
import quantityInput from './woocommerce/components/quantity-input';
import checkout from './woocommerce/checkout';
import edgetagInitiateCheckout from './edgetag/initiateCheckout';
import utmCookie from './global/utmCookie';

document.addEventListener('DOMContentLoaded', function (event) {
    if (!$('body').hasClass('page-template-jackpot')) {
        headerCart();
    }

    headerSearch();
    backToTop();
    subscribePopup();
    products();
    faq();
    jackpot();
    payments();
    special();
    createNotices();
    handleCookie();
    checkout();
    edgetagInitiateCheckout();
    utmCookie();

    if ($('.woocommerce-order-pay')) {
        timer(15);
    }

    singleProduct.initialize();

    if ($('.js-product-card--cta').length > 0) {
        productCard();
    }

    if ($('.js-jackpot--container').length > 0) {
        variables.jackpotController = new Jackpot($('.js-jackpot--container'));

        let jackpotTimeoutSeconds = Number($('.js-wheel--timeout').data('time')) * 60;
        let jackpotTimeoutWrapper = $('.js-wheel--timeout');

        variables.jackpotController.startTimer(jackpotTimeoutSeconds, jackpotTimeoutWrapper);
    }

    mobileMenu();
    stickyHeader();

    // Add html to quantity
    quantityInput();

    // Delete local storage
    if ($('.js-thank-you--order').length > 0) {
        localStorage.removeItem('order');
        localStorage.removeItem('status');
    }

    if (new URLSearchParams(window.location.search).has('afacr_cart_id')) {
        $('.first-step__submit').click();
    }
});
