export let dynamicOptionData = {};

let combinations = [];
if (menaObject.product !== null && menaObject.product.dynamic_variations !== null) {
    combinations = menaObject.product.dynamic_variations;
}

export function dynamicVariations() {
    getDynamicOptionData();

    $('.c-woo-order-box').on('click', '.js-dynamic-variations--option', function () {
        const $option = $(this);
        const $parent = $option.closest('.js-dynamic-variations--variations');

        const termName = $option.data('option-slug');
        const taxonomyName = $option.closest('.js-dynamic-variations--var-item').data('name');

        const filteredCombinations = combinations.filter(combination => {
            let taxonomy = combination[taxonomyName];
            if (taxonomy) {
                return taxonomy === termName;
            }
        });

        const $otherTaxonomies = $parent.find(`.js-dynamic-variations--var-item:not([data-name=${taxonomyName}])`);

        $otherTaxonomies.each(function (i, taxonomy) {
            const $taxonomy = $(taxonomy);
            const taxonomyName = $taxonomy.data('name');
            const $terms = $taxonomy.find('.js-dynamic-variations--option');
            const allowedTermsFromThisTaxonomy = [];

            filteredCombinations.forEach(combination => {
                allowedTermsFromThisTaxonomy.push(combination[taxonomyName]);
            });

            $terms.each(function (i, term) {
                const $term = $(term);
                const termName = $term.data('option-slug');

                if (!allowedTermsFromThisTaxonomy.includes(termName)) {
                    if (!$term.hasClass('disabled')) {
                        $term.addClass('disabled');
                    }
                } else {
                    if ($term.hasClass('disabled')) {
                        $term.removeClass('disabled');
                    }
                }
            });
        });

        if (!$option.hasClass('active')) {
            $option.siblings('.active').removeClass('active');
            $option.addClass('active');
        }

        getDynamicOptionData();
    });
}

export function duplicateDynamicOptionContainer(quantity) {
    if (isNaN(quantity)) {
        return;
    }

    let $variations = $('.js-dynamic-variations--variations');

    if ($variations.length !== quantity) {

        if ($variations.length < quantity) {
            let difference = quantity - $variations.length;

            for (let i = 0; i < difference; i++) {
                let $lastVariation = $variations.last();
                let $newVariation = $lastVariation.clone();
                $lastVariation.after($newVariation);
            }
        } else {
            let difference = $variations.length - quantity;

            for (let i = 0; i < difference; i++) {
                $variations = $('.js-dynamic-variations--variations');
                let $lastVariation = $variations.last();
                $lastVariation.remove();
            }
        }

        getDynamicOptionData();
    }
}

function getDynamicOptionData() {
    const $variations = $('.js-dynamic-variations--variations');
    const $variationsCount = $variations.length;

    $variations.each(function (i, optionContainer) {
        let variationData = {};
        const $varItemContainers = $(optionContainer).find('.js-dynamic-variations--var-item');

        $varItemContainers.each(function (i, varItemContainer) {
            const $varItem = $(varItemContainer);
            const $optionItem = $varItem.find('.js-dynamic-variations--option.active');
            const varItemName = $varItem.data('name');
            variationData[varItemName] = {};
            variationData[varItemName].id = $optionItem.data('option-id');
            variationData[varItemName].name = $optionItem.data('option-name');
            variationData[varItemName].slug = $optionItem.data('option-slug');

            $varItem.find('.js-dynamic-variations--label').text(variationData[varItemName].name);
        });

        dynamicOptionData[i] = variationData;
    });

    const dynamicOptionDataCount = Object.keys(dynamicOptionData).length;
    if (dynamicOptionDataCount !== $variationsCount) {
        if (dynamicOptionDataCount > $variationsCount) {
            let difference = dynamicOptionDataCount - $variationsCount;
            for (let i = 0; i < difference; i++) {
                delete dynamicOptionData[dynamicOptionDataCount - i - 1];
            }
        }
    }
}
